
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';

export const SET_OFFER = 'SET_OFFER';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_OPEN_TOOLTIP = 'SET_OPEN_TOOLTIP';
export const SET_OPEN_TIMELINE = 'SET_OPEN_TIMELINE';
export const SET_TEMPLATE = 'SET_TEMPLATE';

export const SET_CASE_BOT_EMAIL = 'SET_CASE_BOT_EMAIL';
export const SET_CASE_BOT_FIELDS = 'SET_CASE_BOT_FIELDS';

export const SET_ROLLBAR_CONFIG = 'SET_ROLLBAR_CONFIG';