import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'

// I18n
import { withTranslation } from "react-i18next";

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import Typography from '@material-ui/core/Typography';
import AnimateHeight from 'react-animate-height';



class ItemWithTimeline extends Component {

    render() {
        const {
            seperator, children,
            openTimeline, isleftSide,

            item, t
        } = this.props;

        const { title, subject, id, disabled } = item;

        const horizontalSeperatorStyle = {
            borderTop: '2px solid #ffb307',

            marginTop: 'calc(1em + 2px)',

            height: 'calc(3em)', // how far the 'arm' extends downwards
            width: 'calc(100% - 42px)' // the 42px are the 32px we are widening by (16px x 2 (padding)) and the 10px margin + padding an icon has
        }

        // Set correct border for the corresponding side
        horizontalSeperatorStyle[isleftSide ? 'borderTopLeftRadius' : 'borderTopRightRadius'] = 35;
        horizontalSeperatorStyle[isleftSide ? 'borderLeft' : 'borderRight'] = '2px solid #ffb307';

        // Left : Add the width of a icon to push the line closer to the centerline
        // Right: Add the margin of an icon to the line, to move it right to correct amount. found that '+ 1px' needs to be added
        // horizontalSeperatorStyle.marginLeft = isleftSide ? 'calc(1em + 2px)' : 'calc(0.5em + 1px)';
        horizontalSeperatorStyle.marginLeft = isleftSide ? 'calc(1em + 0px)' : 'calc(0.5em + 1px)';


        const alignment = isleftSide ? 'left' : 'right'

        const timelineStyle = {
            padding: 0,
            flexDirection: 'column',
            marginRight: isleftSide ? '0px' : 'calc(1em - 2px)' // 'push it away from the egde on right side'
        }

        const itemStyle = {
            padding: 0,
            minHeight: 100,
            opacity: disabled ? 0.4 : 1,
        }

        return (
            <TimelineItem style={itemStyle} data-testid="item-with-timeline">
                <TimelineOppositeContent style={{ cursor: 'default' }}>
                    <Typography variant="body2" style={{ color: 'lavender', letterSpacing: '0.75px' }}>
                        {t(title)}
                    </Typography>
                    <Typography variant="caption" style={{ color: 'lavender', letterSpacing: 1 }}>
                        {t(subject)}
                    </Typography>
                </TimelineOppositeContent>

                {seperator}

                <TimelineContent style={{ width: 0 }}>
                    <AnimateHeight
                        duration={500}
                        style={{ width: 'calc(100% + 32px)', marginLeft: isleftSide ? 'auto' : '-16px' }}
                        height={id && id === openTimeline ? 'auto' : '0%'} // see props documentation below
                    >
                        <div style={{ ...horizontalSeperatorStyle }} />

                        <Timeline style={{ ...timelineStyle }} align={alignment}>
                            {children}
                        </Timeline>

                    </AnimateHeight>
                </TimelineContent>
            </TimelineItem>
        )

    }

}
const mapStateToProps = ({ isMobile, openTimeline }) => {
    return { isMobile, openTimeline }
}

const mapDispatchToProps = (dispatch) => { return {} }

export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(ItemWithTimeline)
