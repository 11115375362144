import { request } from '../index';

const base = 'offers'
const getOffer = (id) => { return request(`${base}/${id}`, 'GET', 200); }

const uploadDocument = (body) => { return request(`${base}/document`, 'POST', 201, body); }


export {
    getOffer,
    uploadDocument
}