import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// I18n
import { withTranslation } from "react-i18next";
import Subject from './Subject';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import Typography from '@material-ui/core/Typography';


class SideItem extends React.Component {

    render() {
        const { children, item, isMobile, isLast, t } = this.props;
        const { title, subject, i18nKey } = item;

        return (
            <TimelineItem style={{ minHeight: isLast ? 'fit-content' : 62 }}>
                <TimelineOppositeContent style={{ display: 'none' }}>
                </TimelineOppositeContent>

                {children}

                <TimelineContent style={{
                    textAlign: 'left',
                    padding: isMobile ? '6px 0px 0px 3px' : 'auto',

                    width: '100%', textOverflow: 'ellipsis',
                    display: 'block', overflow: 'hidden',
                }}>
                    <Typography variant="body2" style={{ color: 'lavender', letterSpacing: '0.75px' }}>
                        {t(title)} <br />
                    </Typography>

                    <Typography variant="caption" style={{ color: 'lavender', letterSpacing: 1 }}>
                        <Subject content={subject} i18nKey={i18nKey} />
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        )
    }

}

const mapStateToProps = ({ isMobile }) => {
    return { isMobile }
}


const mapDispatchToProps = () => {
    return {}
}


export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(SideItem)

