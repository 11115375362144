import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

// I18n
import { useTranslation } from "react-i18next";

const ConfirmButton = ({ loading, loadingSuccess, beenConfirmed, confirmImages }) => {
    const [t] = useTranslation('vehicle_valuation')
    // console.log(tt);
    // const [t, i18n] = useTranslation('vehicle_valuation');
    return (
        <button
            className="yellow-button"
            onClick={confirmImages}
            style={{ fontSize: 14, backgroundColor: '#40ba40', padding: '12px 35px', margin: 0, width: '100%', marginTop: '1em' }}
        >
            {loading === true && loadingSuccess === null && (<CircularProgress size={24} style={{ color: 'white' }} />)}
            {loading === false && loadingSuccess === true && (<CheckCircleIcon size={24} />)}
            {loading === false && loadingSuccess === false && (<WarningIcon size={24} />)}
            {loading === false && loadingSuccess === null && beenConfirmed === true && (
                <p style={{ margin: 0 }}>
                    {t('confirm-images.button.confirmed')}
                </p>)}
            {loading === false && loadingSuccess === null && beenConfirmed === false && (
                <p style={{ margin: 0, textAlign: 'center', width: '100%' }}>
                    {t('confirm-images.button.directions')}
                </p>
            )}
        </button>
    )
}

export default ConfirmButton;
