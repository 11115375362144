import React from 'react';
import { connect } from 'react-redux'
import queryString from 'query-string';

import { setOffer, setTemplate } from '../../../actions'
import { createTemplate } from '../Template'

import MockDate from 'mockdate'

class StateManipulator extends React.Component {
    // The purpose of this is testing. To be able to manipulate things such as time among others.

    resetTemplate() {
        const d = new Date(this.props.offer.Offer_Signed__c);
        setTimeout(() => {
            const params = queryString.parse(this.props.location.search);
            if (!params.daysAfter || isNaN(params.daysAfter)) {
                let pathname = this.props.location.pathname;
                let searchParams = new URLSearchParams(this.props.location.search);
                searchParams.set('daysAfter', 0);
                this.props.history.push({ pathname, search: searchParams.toString() });
                MockDate.set(d.setDate(d.getDate()));
            } else {
                MockDate.set(d.setDate(d.getDate() + parseInt(params.daysAfter)));
            }

            this.props.setTemplate(createTemplate(this.props.offer));
        }, 150)
    }


    componentDidMount() {
        let searchParams = new URLSearchParams(this.props.location.search);
        let pathname = this.props.location.pathname;
        searchParams.set('daysAfter', 0);
        this.props.history.push({ pathname, search: searchParams.toString() });
    }

    render() {
        return (

            <div style={{ position: 'fixed', right: '1em', bottom: '1em', display: 'flex', flexDirection: 'column' }}>
                <div style={{ textAlign: 'center' }}> {new Date().toLocaleDateString().replace(/\//g, '-')} </div>

                <button onClick={() => { this.resetTemplate(); }}>Update</button>

                <div style={{ display: 'flex' }}>
                    <button style={{ flexGrow: 1 }}
                        onClick={() => {
                            let pathname = this.props.location.pathname;
                            let searchParams = new URLSearchParams(this.props.location.search);
                            const params = queryString.parse(this.props.location.search);

                            searchParams.set('daysAfter', parseInt(params.daysAfter) - 1);
                            this.props.history.push({ pathname, search: searchParams.toString() });
                            this.resetTemplate();
                        }}
                    >-1 Day</button>

                    <button style={{ flexGrow: 1 }}
                        onClick={() => {
                            let pathname = this.props.location.pathname;
                            let searchParams = new URLSearchParams(this.props.location.search);
                            const params = queryString.parse(this.props.location.search);

                            searchParams.set('daysAfter', parseInt(params.daysAfter) + 1);
                            this.props.history.push({ pathname, search: searchParams.toString() });
                            this.resetTemplate();
                        }}
                    >+1 Day</button>
                </div>

                <div style={{ display: 'flex' }}>
                    <button style={{ flexGrow: 1 }}
                        onClick={() => {
                            let pathname = this.props.location.pathname;
                            let searchParams = new URLSearchParams(this.props.location.search);
                            const params = queryString.parse(this.props.location.search);

                            searchParams.set('daysAfter', parseInt(params.daysAfter) - 30);
                            this.props.history.push({ pathname, search: searchParams.toString() });
                            this.resetTemplate();
                        }}
                    >-30 Days</button>

                    <button style={{ flexGrow: 1 }}
                        onClick={() => {
                            let pathname = this.props.location.pathname;
                            let searchParams = new URLSearchParams(this.props.location.search);
                            const params = queryString.parse(this.props.location.search);

                            searchParams.set('daysAfter', parseInt(params.daysAfter) + 30);
                            this.props.history.push({ pathname, search: searchParams.toString() });
                            this.resetTemplate();
                        }}
                    >+30 Days</button>
                </div>

                <br />
                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.RegistrationDocStatus__c = 'Missing';
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}
                >Missing Tradein Reg</button>

                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.RegistrationDocStatus__c = 'Uploaded';
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}
                >Uploaded Tradein Reg</button>

                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.RegistrationDocStatus__c = 'Ok';
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}
                >Ok Tradein Reg</button>

                <br />
                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.Vehicle__r.ECC__c = 12;
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}>
                    No Payment</button>

                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.Vehicle__r.ECC__c = 64;
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}
                >Ok Payment</button>


                <br />
                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.Tradein__r = null;
                        offer.Tradein__c = null;
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}>
                    Remove Tradein</button>

                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.Tradein__c = "a010Q000009bySAQAY";
                        offer.Tradein__r = {
                            Make__c: "Opel", Model__c: "Karl",
                            Registration_Doc__c: "Kunde Upload", // Received, Uploaded, Requested, Kunde Upload, Missing
                            Unpaid_Balance__c: true
                        }
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}
                >Set Tradein</button>

                <br />
                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.TradeinOutstandingBalanceStatus__c = 'Missing';
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}>
                    Remove Tradein Balance</button>

                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.TradeinOutstandingBalanceStatus__c = 'Uploaded';
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}>
                    Uploaded Tradein Balance</button>

                <button
                    onClick={() => {
                        const offer = this.props.offer;
                        offer.TradeinOutstandingBalanceStatus__c = 'Ok';
                        this.props.setOffer(offer);
                        this.resetTemplate();
                    }}>
                    Ok Tradein Balance</button>
            </div >
        )
    }

}

const mapStateToProps = ({ offer, template }) => {
    return { offer, template }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOffer: offer => dispatch(setOffer(offer)),
        setTemplate: template => dispatch(setTemplate(template)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StateManipulator);
