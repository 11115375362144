import React from 'react';

// I18n
import { useTranslation } from "react-i18next";

// Import css
import styles from './LanguageSelectors.module.css';

// Country icons
import DA from './icons/denmark-icon.svg';
import EN from './icons/gb-icon.svg';

const LanguageButton = ({ language, icon, first }) => {
    const i18n = useTranslation()[1];
    return (
        <button
            className={`
                ${styles.button} 
                ${i18n.language.includes(language) ? styles.active : ''}
                ${first ? styles.first : ''}
            `}
            onClick={() => i18n.changeLanguage(language)}
        >
            <img src={icon} alt={language} className={styles.img_icon} />
        </button>

    )
}




export const LanguageSelectors = () => {
    return (
        <div className={styles.container}>
            <LanguageButton language='da' icon={DA} />
            <div className={styles.splitter}></div>
            <LanguageButton language='en' icon={EN} />
        </div>
    )
}