import {
    SET_OFFER,
    SET_IS_MOBILE,
    SET_OPEN_TOOLTIP,
    SET_OPEN_TIMELINE,
    SET_TEMPLATE,
    // SET_CASE_BOT_EMAIL,
    SET_CASE_BOT_FIELDS,
    SET_IS_AUTHENTICATED,

    SET_ROLLBAR_CONFIG
} from './actions/types'


const initialState = {
    authenticated: null,

    offer: null,
    attachments: [],
    isMobile: false,
    openTooltip: null,
    openTimeline: null,
    template: null,

    casebot: {
        email: null
    },

    rollbar: null
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_IS_AUTHENTICATED: {
            const { authenticated } = action
            return { ...state, authenticated }
        }

        case SET_OFFER: {
            const { offer } = action
            return { ...state, offer }
        }

        case SET_IS_MOBILE: {
            const { isMobile } = action
            return { ...state, isMobile }
        }

        case SET_OPEN_TOOLTIP: {
            const { tooltip } = action
            return { ...state, openTooltip: tooltip }
        }

        case SET_OPEN_TIMELINE: {
            const { timeline } = action
            return { ...state, openTimeline: timeline }
        }

        case SET_TEMPLATE: {
            const { template } = action
            return { ...state, template }
        }

        case SET_CASE_BOT_FIELDS: {
            const { fields } = action
            return { ...state, casebot: { ...state.casebot, ...fields } };
        }

        case SET_ROLLBAR_CONFIG: {
            const { config } = action
            return { ...state, rollbar: config };
        }


        default: return state;
    }
}

export default reducer;
