import React from 'react';


const Icon = props => (
    <svg
        style={{ fill: props.color ? props.color : '' }}
        width="24" height="24"
        viewBox={props.icon.viewBox}
    >
        <path
            style={{
                strokeWidth: props.icon.strokeWidth,
                stroke: 'white'
            }} d={props.icon.path}
        ></path>
    </svg >
);

export default Icon;