
import { request } from '../index';

const login = (body) => { return request('auth/login', 'POST', 200, body); }
const check = (offerId) => { return request('auth/check/' + offerId, 'GET', 200); }



export {
    login,
    check
}