import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// I18n
import { withTranslation } from "react-i18next";

import Subject from '../Subject';
import PaymentOverview from './PaymentOverview';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


// Get template ids so we can see what item we're; rendering
import { templateItemIds, anchors } from '../../Constants';

class MainItem extends React.Component {

    render() {
        const { children, item = {}, isMobile, t } = this.props;
        const { disabled, main, offset } = item;

        const contentStyle = {
            display: main == null ? 'none' : 'unset',
            padding: isMobile ? '6px 8px 6px 8px' : 'auto',
        }

        const itemStyle = {
            justifyContent: 'center',
            minHeight: 100,
            opacity: disabled ? 0.4 : 1,
        }



        // // We have to set the variable here, because <PaymentOverview /> won't update when an achor is set
        const showPaymentModal = window.location.href.includes(anchors.paymentOverviewAnchor) ? true : false;
        // console.log('showPaymentModal', showPaymentModal);

        return (
            <TimelineItem style={{ ...itemStyle }} data-testid="main-timeline-item">
                <TimelineOppositeContent style={{ ...contentStyle }}>
                    {offset && (offset.title || offset.subject) && (
                        <div data-testid="offset-wrapper">
                            <Typography variant="body2" style={{ color: 'lavender', letterSpacing: '0.75px' }}>
                                {t(offset.title)}
                            </Typography>
                            <Typography variant="caption" style={{ color: 'lavender', letterSpacing: 1 }}>
                                <Subject content={offset.subject} i18nKey={offset.i18nKey} t={t} />
                            </Typography>
                        </div>
                    )}
                </TimelineOppositeContent>

                {children}

                <TimelineContent style={{ ...contentStyle }}>
                    {main && (main.title || main.subject) && (
                        <Paper elevation={3} style={{ padding: '0.5rem', backgroundColor: '#504e4e', color: 'white' }} data-testid="paper">
                            <Typography variant="body2" style={{ color: 'lavender', letterSpacing: '0.75px' }}>
                                {t(main.title)}
                            </Typography>
                            <Typography variant="caption" style={{ color: 'lavender', letterSpacing: 1 }}>
                                <Subject content={main.subject} i18nKey={main.i18nKey} t={t} />
                            </Typography>

                            {/* If it's a payment item, we render the payment overview. It's a modal that shows itself when the correct anchor is in the url */}
                            {/* {item.id === templateItemIds.payment.id && (
                                <PaymentOverview showModal={showPaymentModal} href={window.location.href} />
                            )} */}
                        </Paper>
                    )}
                </TimelineContent>
            </TimelineItem>
        )
    }

}

const mapStateToProps = ({ isMobile }) => {
    return { isMobile }
}

const mapDispatchToProps = () => { return {} }



export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(MainItem)

