import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

// Import css
import styles from './Progress.module.css';

class Progress extends Component {

    state = {
        barWidth: null,
        totalSteps: null,
        completedSteps: null
    }

    setProgress() {
        const { angles, completedAngles } = this.props;
        if (angles && completedAngles) {

            let barWidth,
                lastContinousDot,
                completedSteps = 0;

            const requiredAngles = angles.filter(a => a.required);

            // The slider in between the steps should go to the last dot of a continous line
            // If the angle is not completed and is required, it is the last continous dot. 
            // If it's not and is required, it is completed
            requiredAngles.some((angle, i) => {
                if (
                    !completedAngles.includes(angles[i === angles.length - 1 ? i : i + 1].id) &&
                    angle.required
                ) {
                    lastContinousDot = angle.id; return true;
                } else return false;

            })

            // Calculate required, completed steps
            completedSteps = requiredAngles.reduce((acc, angle) => {
                if (angle.required && completedAngles.includes(angle.id)) {
                    return acc += 1;
                } else return acc;
            }, completedSteps)

            // If completed - 'lastContinousDot' is false.
            if (!lastContinousDot) lastContinousDot = requiredAngles[requiredAngles.length - 1].id;

            // If step for last continous dot exists, set progress to it.
            const step = document.getElementById(lastContinousDot);
            // console.log('step.style.width', step.getBoundingClientRect(), step.style.width, step.style, step.offsetLeft, lastContinousDot);

            if (step) {
                barWidth = (step.offsetLeft + (step.getBoundingClientRect().width / 2));
            }

            // document.getElementById('percent').style.width = `${width}px`;

            this.setState({
                barWidth, completedSteps,
                totalSteps: requiredAngles.length,
            })
        }
    }

    componentDidMount() {
        // Update the slider and progress number
        this.setProgress();
    }


    componentDidUpdate(prevProps) {
        // If the 'completedAngles' has changed, update progress
        if (prevProps.completedAngles && this.props.completedAngles &&
            prevProps.completedAngles.length !== this.props.completedAngles.length
        ) {
            // Update the slider and progress number
            this.setProgress();
        }
    }

    render() {
        const { completedAngles, angles, currentAngle } = this.props;
        const { barWidth, totalSteps, completedSteps } = this.state;

        return (
            <div className={styles.container}>
                <div className={styles.relative}>
                    <div className={styles.progress}>
                        <div className={styles.percent} style={{ width: `${barWidth}px` }} id='percent'></div>
                    </div>

                    <div className={styles.steps}>
                        {angles.map(angle => {
                            if (angle.required) {
                                return (
                                    <div key={angle.id} id={angle.id}
                                        className={`
                                            ${styles.step} 
                                            ${completedAngles.includes(angle.id) ? styles.completed : ''}
                                            ${angle.id === currentAngle.id ? styles.selected : ''}
                                        `}
                                    ></div>
                                )
                            } else return '';
                        })}
                    </div>
                </div>
                
                <div className={styles.progress_number}>
                    <Typography variant="h6">
                        {totalSteps !== null && completedSteps !== null && (
                            `${completedSteps} / ${totalSteps}`
                        )}
                    </Typography>
                </div>
            </div>
        )
    }

}

export default Progress;