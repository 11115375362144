import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { createStore } from 'redux'
import { Provider } from 'react-redux'

import MainRouter from './components/MainRouter/MainRouter';
import reducer from './reducer';

// CSS specificity problem:
// Our CSS is loaded before the Material UI's styles, and therefore is overridden by them.
import { StylesProvider } from "@material-ui/core/styles"


// Internationalization
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


import vehicle_valuation_en from "./translations/en/vehicle_valuation.json";
import vehicle_valuation_dk from "./translations/dk/vehicle_valuation.json";

import pre_delivery_en from "./translations/en/pre-delivery.json";
import pre_delivery_dk from "./translations/dk/pre-delivery.json";

import main_en from "./translations/en/main.json";
import main_dk from "./translations/dk/main.json";

import login_en from "./translations/en/login.json";
import login_dk from "./translations/dk/login.json";


// I18next init options and 'LanguageDetector' options
i18next.use(LanguageDetector).init({
	interpolation: { escapeValue: false },  // React already does escaping
	fallbackLng: 'da',
	detection: { caches: ['localStorage', 'cookie'] },
	resources: {
		en: {
			vehicle_valuation: vehicle_valuation_en,
			pre_delivery: pre_delivery_en,
			login: login_en,
			main: main_en
		},
		da: {
			vehicle_valuation: vehicle_valuation_dk,
			pre_delivery: pre_delivery_dk,
			login: login_dk,
			main: main_dk
		},
	},
});


ReactDOM.render(
	<Provider store={createStore(reducer)}>
		<React.StrictMode>
			<I18nextProvider i18n={i18next}>
				<StylesProvider injectFirst> {/* 'injectFirst' allows us to override styling with CSS classes */}
					<MainRouter />
				</StylesProvider>
			</I18nextProvider>
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
