import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import queryString from 'query-string';

import { getOffer } from '../../api/offer';
import {
    setOffer, setIsMobile, setOpenTimeline,
    setOpenTooltip, setTemplate, setCaseBot
} from '../../actions'

import logo from './icons/AM.png';

// I18n
import { withTranslation } from "react-i18next";

import Timeline from '@material-ui/lab/Timeline';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';

import CircularProgress from '@material-ui/core/CircularProgress';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PersonIcon from '@material-ui/icons/Person';

import Typography from '@material-ui/core/Typography';

// Timeline components
import Seperator from './TimelineComponents/Seperator/Seperator';
import SideItem from './TimelineComponents/SideItem';
import MainItem from './TimelineComponents/MainItem/MainItem';
import ItemWithTimeline from './TimelineComponents/ItemWithTimeline';
import FileUploadItem from './TimelineComponents/FileUploadItem';
import AMLogo from './TimelineComponents/AMLogo';
import StateManipulator from './utils/StateManipulator';

import { createTemplate } from './Template'

import { mobileCheck } from './utils/Utils';
import { colors, itemTypes } from './Constants'



class PreDelivery extends Component {

    state = {
        loading: true,
    }

    componentDidMount() {
        const {
            location, setTemplate, rollbar,
            setOffer, offer, t, setIsMobile
        } = this.props;

        // Set Title
        window.document.title = t('document-title');

        // Do mobilecheck
        const isMobile = mobileCheck();
        setIsMobile(isMobile);


        if (location && location.search) {
            const params = queryString.parse(location.search);

            // if theres no offer in redux, get and set it
            if (params.offerId && !offer) {

                getOffer(params.offerId)
                    .then(({ success, resp }) => {
                        if (success) {
                            console.log('got offer:', resp.data.offer);

                            // Set offer in redux
                            setOffer(resp.data.offer);

                            // Create template from offer
                            const template = createTemplate(resp.data.offer);

                            // If it's the first time the user accesses the site, show that they can click on icons
                            // Set the 'showInstructions' key by cloning the icon with the newly set value
                            const loadedKey = 'loaded';
                            if (!window.localStorage.getItem(loadedKey)) {
                                // Checking isValidElement is the safe way
                                let icon = template.main[0].icon;
                                if (React.isValidElement(icon)) {
                                    icon = React.cloneElement(icon, { showInstructions: true });
                                }
                                template.main[0].icon = icon;
                                window.localStorage.setItem(loadedKey, true);
                            }

                            // Set template in redux
                            setTemplate(template);

                        } else {
                            console.log('not successful: ' + success);
                            rollbar.warn('Not Successful Getting Offer', { success, resp });
                        };
                    })
                    .catch(err => {
                        console.log('error getting offer', err);
                        rollbar.error('Error Getting Offer', err);
                    })
                    .finally(() => this.setState({ loading: false }));
            } else {
                setTemplate(createTemplate(offer));
                this.setState({ loading: false });
            }

        }
    }


    handleTimeline(id) {
        const { openTimeline, setOpenTimeline } = this.props;
        // if the selected timeline is already selected, hide the timeline by setting 'openTimeline' to null
        setOpenTimeline(openTimeline === id ? null : id);
    }


    render() {
        const {
            offer, openTooltip, isMobile,
            template, setOpenTooltip,
            t,
            history, location
        } = this.props;

        const { loading } = this.state;




        return (
            <div style={{ color: 'white', marginTop: '3em', display: 'flex', justifyContent: 'center' }}>
                {offer && (
                    <div style={{ maxWidth: 800, width: '100%', marginBottom: '15%' }}>

                        {/* Main Timeline */}
                        <Timeline align="alternate" style={{ padding: isMobile ? 0 : 5 }} onClick={() => {
                            // If we're mobile and a tooltip is open, hide it when the screen is clicked                                
                            if (isMobile && openTooltip) { setOpenTooltip(null); console.log('Hiding tooltip') }
                        }}>

                            {/* AM Logo */}
                            <AMLogo offer={offer} />



                            {/* Template Renderer */}
                            {template && template.main.map((item, index) => {
                                let components = []; // List of components to add to the view

                                const isLast = index === template.main.length - 1;

                                if (item.type === itemTypes.itemWithTimeline) {
                                    // Whether the timeline is at the left or right
                                    const isleftSide = index % 2 === 0;

                                    components.push(
                                        <ItemWithTimeline
                                            item={item} isleftSide={isleftSide} key={item.id}
                                            seperator={
                                                <Seperator
                                                    item={item} isLast={isLast}
                                                    btnClick={() => { this.handleTimeline(item.id) }}
                                                />
                                            }
                                        >

                                            {item.subItems && item.subItems.map((subItem, index) => {
                                                const isLastSub = index === item.subItems.length - 1;

                                                if (subItem.type === itemTypes.subItem) {
                                                    return (
                                                        <SideItem item={subItem} key={subItem.id} isLast={isLastSub} data-testid="side-item">
                                                            <Seperator item={subItem} isLast={isLastSub} />
                                                        </SideItem>
                                                    )
                                                } else if (subItem.type === itemTypes.fileUpload) {
                                                    return (
                                                        <FileUploadItem item={subItem} key={subItem.id} isLast={isLastSub} data-testid="file-upload-item">
                                                            <Seperator item={subItem} isLast={isLastSub} />
                                                        </FileUploadItem>
                                                    )
                                                } else return null;
                                            })}

                                        </ItemWithTimeline>
                                    )

                                } else if (item.type === itemTypes.mainItem) {
                                    components.push(
                                        <MainItem item={item} key={item.id}>
                                            <Seperator item={item} isLast={isLast} />
                                        </MainItem>
                                    )

                                } else if (item.type === itemTypes.thankYou) {
                                    components.push(
                                        <MainItem key={item.id}>
                                            <TimelineSeparator style={{ flexBasis: 'auto' }}>
                                                <Typography
                                                    style={{ padding: '0.25em 0.25em 0.25em 0.25em', textAlign: 'center' }}
                                                    variant="h6"
                                                >
                                                    {t('main-timeline.thanks')}
                                                </Typography>
                                                
                                                {/* Only show connector if item isn't the last */}
                                                {!isLast && (
                                                    <TimelineConnector style={{ backgroundColor: '#ffb307', opacity: item.connectorDisabled ? 0.4 : 1 }} />
                                                )}

                                            </TimelineSeparator>
                                        </MainItem>
                                    )
                                }

                                return components
                            })}
                        </Timeline>
                    </div >
                )}

                {loading && (
                    <div style={{ paddingTop: '4rem' }}>
                        <CircularProgress size={'4rem'} style={{ color: colors.AMYellow }} />
                    </div>
                )}


                {/* For testing */}
                {/* <StateManipulator location={location} history={history} offer={offer} /> */}

            </div >
        )
    }
}



const mapStateToProps = ({ offer, openTimeline, openTooltip, isMobile, template, rollbar }) => {
    return {
        offer, openTimeline, rollbar,
        openTooltip, isMobile, template
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOffer: offer => dispatch(setOffer(offer)),

        setOfferAndCaseBotMail: (offer, fields) => {
            dispatch(setOffer(offer));
            dispatch(setCaseBot(fields))
        },

        setTemplate: template => dispatch(setTemplate(template)),
        setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
        setOpenTimeline: timeline => dispatch(setOpenTimeline(timeline)),
        setOpenTooltip: tooltip => dispatch(setOpenTooltip(tooltip)),
    }
}

export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(PreDelivery)


