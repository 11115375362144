import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// I18n
import { withTranslation } from "react-i18next";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Modal from '@material-ui/core/Modal';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import { templateItemIds, anchors } from '../../Constants';


const Overview = (props) => {
    const finalAmount = props.offer.AM_Final_Amount__c;
    const nf = new Intl.NumberFormat("en-DK", { minimumFractionDigits: 2 });
    const formattedValue = nf.format(finalAmount);
    
    return (
        <TableContainer component={Paper} >
            <Table size='small'>
                <TableHead>
                </TableHead>
                <TableBody>
                    {/* <TableRow>
                                <TableCell align="left">Restgæld iflg. slutseddel:</TableCell>
                                <TableCell align="right">kr. 107.000,00</TableCell>
                            </TableRow> */}
                    {/* <TableRow style={{}}>
                                <TableCell align="left">Korrekt restgæld:</TableCell>
                                <TableCell align="right">kr. 113.668,17</TableCell>
                                <div style={{ borderBottom: '2px solid black', width: '100%', marginBottom: '2px' }} />
                                <div style={{ borderBottom: '2px solid black', width: '100%' }} />
                            </TableRow> */}
                    <TableRow>
                        <TableCell align="left">At Betale:</TableCell>
                        <TableCell align="right">
                            <b>kr. {formattedValue}</b>
                            <div style={{ borderBottom: '2px solid black', width: '100%', marginBottom: '2px' }} />
                            <div style={{ borderBottom: '2px solid black', width: '100%' }} />
                        </TableCell>
                    </TableRow>
                    {/* ))} */}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


class PaymentOverview extends React.Component {

    state = { open: false };

    componentDidMount() {
        this.setState({ open: this.props.showModal });
    }

    closeModal() { window.document.getElementById('remove-anchor').click(); }

    render() {
        const { offer, isMobile, showModal } = this.props;
        const { open } = this.state;

        /* 
            For non-mobile, render the overview in the box.
            On mobile, render it in a modal
        */

        // We have to set the variable here, because <PaymentOverview /> won't update when an achor is set
        const showPaymentModal = window.location.href.includes(anchors.paymentOverviewAnchor) ? true : false;

        if (isMobile) {
            return (
                <div>
                    <a id='remove-anchor' style={{ color: '#ffb307', display: 'none' }} href='#' />
                    <Modal open={showPaymentModal} onClose={this.closeModal}>
                        <div style={{ marginTop: '40vh', width: '90%', marginLeft: '5%' }}>
                            <Overview offer={offer} />
                        </div>
                    </Modal>
                </div>
            )
        } else if (!isMobile) { return (<div><br /><Overview offer={offer} /></div>) }
        else { return (<div />); }
    }

}

const mapStateToProps = ({ isMobile, offer }) => {
    return { isMobile, offer }
}


const mapDispatchToProps = () => {
    return {}
}


export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(PaymentOverview)

