import {
    SET_OFFER,
    SET_IS_MOBILE,
    SET_OPEN_TOOLTIP,
    SET_OPEN_TIMELINE,
    SET_TEMPLATE,
    // SET_CASE_BOT_EMAIL,
    SET_CASE_BOT_FIELDS,
    SET_IS_AUTHENTICATED,
    
    SET_ROLLBAR_CONFIG
} from './types'

export const setOffer = (offer) => {
    return {
        type: SET_OFFER,
        offer
    }
}

export const setIsMobile = (isMobile) => {
    return {
        type: SET_IS_MOBILE,
        isMobile
    }
}

export const setOpenTooltip = (tooltip) => {
    return {
        type: SET_OPEN_TOOLTIP,
        tooltip
    }
}

export const setOpenTimeline = (timeline) => {
    return {
        type: SET_OPEN_TIMELINE,
        timeline
    }
}

export const setTemplate = (template) => {
    return {
        type: SET_TEMPLATE,
        template
    }
}

export const setCaseBot = (fields) => {
    return {
        type: SET_CASE_BOT_FIELDS,
        fields
    }
}

export const setIsAuthenticated = (authenticated) => {
    return {
        type: SET_IS_AUTHENTICATED,
        authenticated
    }
}

export const setRollbarConfig = (config) => {
    return {
        type: SET_ROLLBAR_CONFIG,
        config
    }
}
