import Rollbar from "rollbar";

const accessToken = 'e8b77b73cc7348d1ae6b26e0882f2e22'
const rollbar = new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.NODE_ENV,
});

export default rollbar;