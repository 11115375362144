
export const colors = {
    'succes': '#5e7b2c',
    'success': '#5e7b2c',
    'error': 'brown',
    'warning': 'orange',

    'AMYellow': '#ffb307',
    'defualt': '#bdbdbd',
    'default': '#bdbdbd',

    // 'gold': '#e0bd00',
    'gold': 'goldenrod',
    'silver': '#bdb5b5',
    'bronze': '#774d24',

    'textError': '#b82020',

}


export const itemTypes = {
    mainItem: 'mainItem',
    itemWithTimeline: 'itemWithTimeline',
    subItem: 'subItem',
    thankYou: 'thankYou',
    fileUpload: 'fileUpload'
}

export const pulseTypes = {
    normal: 'normal',
    medium: 'medium',
    high: 'high'
}

export const locations = {
    'Greve': 'https://www.google.com/maps/place/Agenavej+15,+2670+Greve',
    'Ballerup': 'https://www.google.com/maps/place/Tempovej+17,+2750+Ballerup',
    'Søborg': 'https://www.google.com/maps/place/Gladsaxevej+340,+2860+Søborg',
    'Taastrup': 'https://www.google.com/maps/place/Husmandsvej+3,+2630+Taastrup',
    'Glostrup': 'https://www.google.com/maps/place/Hovedvejen+196,+2600+Glostrup',
    'Kaastrup': 'https://www.google.com/maps/place/Englandsvej+386,+2770+Kastrup',    
}

export const anchors = {
    paymentOverviewAnchor: '#payment'
}


export const templateItemIds = {

    signedBOS: {
        id: 'signedBOS',
        insurance: 'insurance',
        coating: 'coating',
        serviceDeal: 'serviceDeal',
        deliveryTires: 'deliveryTires',
        secondaryTires: 'secondaryTires',
        trailerHitch: 'trailerHitch',
        licensePlates: 'licensePlates',
    },

    tradeinRegistration: {
        id: 'tradein',
        registration: 'tradeinRegistration_fileUpload',
        info: 'tradeinRegistration_info',
        keys: 'tradeinRegistration_keys',
        servicebook: 'tradeinRegistration_servicebook',
        tires: 'tradeinRegistration_tires',
        extra_tires: 'tradeinRegistration_extra_tires'
    },

    payment: {
        id: 'payment',
    },

    delivery: {
        id: 'delivery'
    },

    tradeinOutstandingDebt: {
        id: 'tradeinOutstandingDebt',
        fileUpload: 'tradeinOutstandingDebt_fileUpload',
        info: 'tradeinOutstandingDebt_info'
    },

    complaint: {
        id: 'complaint',
    }
}