import React from 'react';
import TimelineDot from '@material-ui/lab/TimelineDot';
import IconButton from '@material-ui/core/IconButton';


function Dot({ clickHandler, backgroundColor, children, pulse, disabled }) {
    const iconStyle = { padding: 0 }
    const padding = 4; // the iconbutton needs to have padding set to have the correct size
    const pulseClass = pulse ? 'pulse_' + pulse : '';

    if (clickHandler) {
        return (
            <div>
                <TimelineDot style={{ backgroundColor, ...iconStyle }} >
                    <IconButton
                        style={{ padding }}
                        className={disabled ? '' : pulseClass}
                        disabled={disabled}
                        onClick={() => { if (!disabled) clickHandler() }}
                        data-testid="icon-button"
                    >
                        {children}
                    </IconButton>
                </TimelineDot>
            </div>
        )
    } else {
        return (
            <TimelineDot style={{ backgroundColor, ...iconStyle, padding }} className={pulseClass} data-testid="no-handler-dot">
                {children}
            </TimelineDot>
        )
    }
}

export default Dot;