import React from 'react';
import Icon from './icons'
import { ICONS } from './icons/ICONS'
// import { Link } from 'react-router-dom';

import rollbar from '../../rollbar';

import CreateIcon from '@material-ui/icons/Create';
import LockIcon from '@material-ui/icons/Lock';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import {
    IoKeySharp,
    IoDocumentTextOutline,
} from 'react-icons/io5'

import { GoBook } from 'react-icons/go';
import { RiShieldStarLine } from "react-icons/ri";


import {
    colors, itemTypes, pulseTypes,
    locations, templateItemIds, anchors
} from './Constants';
import { mobileCheck } from './utils/Utils';


import Item from './TimelineComponents/Item';
import Tooltip from './TimelineComponents/Tooltip';
// import Popover from '@material-ui/core/Popover';


import 'intl'; // for toLocaleDateString when running tests

export const createTemplate = offer => {
    let template = {};
    template.main = [];

    try {

        // The deadlines are used as dates for different levels of urgency.
        const now = new Date();

        const isMobile = mobileCheck();


        // let correctedDeliveryDate = new Date(offer.Offer_Delivery__c);
        let correctedDeliveryDate = new Date(offer.Handover_Start__c);
        // correctedDeliveryDate = new Date(correctedDeliveryDate.setHours(0));
        // correctedDeliveryDate = new Date(correctedDeliveryDate.setMinutes(0));

        // Bill Of Sales signed
        const documentSigned = new Item({
            id: templateItemIds.signedBOS.id,
            type: itemTypes.itemWithTimeline,

            icon: <CreateIcon style={{ color: 'white' }} />,
            iconColor: offer.Offer_Signed__c ? colors.succes : colors.error,

            pulse: pulseTypes.normal,


            title: 'main-timeline.BOS.title',
            subject: offer.Offer_Signed__c
                ? (new Date(offer.Offer_Signed__c).toLocaleDateString('en-dk').replace(/\//g, '-'))
                : 'main-timeline.BOS.missing-date',
        });

        // Sub-items
        // Insurance
        // const documentSignedSubInsurance = new Item({
        //     id: templateItemIds.signedBOS.insurance,
        //     type: itemTypes.subItem,

        //     icon: <LockIcon />,
        //     iconColor: offer.Insurance_Company__c
        //         ? colors.succes
        //         : colors.error,

        //     title: 'main-timeline.BOS.sub.insurance.title',
        //     subject: offer.Insurance_Company__c
        //         ? offer.Insurance_Company__c
        //         : 'main-timeline.BOS.sub.insurance.no-insurance'
        // }); documentSigned.subItems.push(documentSignedSubInsurance);


        // Service Deal
        // const serviceDeal = offer.Service_Agreement_Level__c;
        // const documentSignedSubServiceDeal = new Item({
        //     id: templateItemIds.signedBOS.serviceDeal,
        //     type: itemTypes.subItem,

        //     icon: <Icon icon={ICONS.SERVICE} color={'white'} />,

        //     // If the Service Agreement Level is available and isn't 'NA' it's either 'Gold', 'Silver' or 'Bronze'
        //     iconColor: !serviceDeal
        //         ? colors.default
        //         : serviceDeal === 'NA'
        //             ? colors.default
        //             : colors[serviceDeal.toLowerCase()]
        //     ,

        //     title: 'main-timeline.BOS.sub.service.title',
        //     subject: (<a style={{ color: '#ffb307' }} href="https://am.dk/autovaerksted/serviceaftale/" target="_blank" rel="noreferrer"></a>),
        //     i18nKey: `pre_delivery:main-timeline.BOS.sub.service.${serviceDeal ? serviceDeal : 'NA'}`
        // });


        // Coating 
        const coatingLevel = offer.Requisition__r ? offer.Requisition__r.CosmeticAddonOptions__c : null;
        const noCoating = !coatingLevel || coatingLevel === 'NA' ? true : false;
        const documentSignedSubCoating = new Item({
            id: templateItemIds.signedBOS.coating,
            type: itemTypes.subItem,

            icon: <RiShieldStarLine style={{ width: 24, height: 24 }} />,

            // If the Coating Level is available and isn't 'NA', show it
            iconColor: noCoating ? colors.default : colors.succes,
            title: 'main-timeline.BOS.sub.coating.title',
            subject: noCoating ?
                'main-timeline.BOS.sub.coating.missing' :
                'main-timeline.BOS.sub.coating.bought'
        });

        // const documentSignedSubServiceDealTooltip = new Tooltip({
        //     title: 'main-timeline.BOS.sub.service.tooltip.title',
        //     subject: 'main-timeline.BOS.sub.service.tooltip.subject'
        // }); documentSignedSubServiceDeal.tooltip = documentSignedSubServiceDealTooltip;
        documentSigned.subItems.push(documentSignedSubCoating);


        // Tires
        const tireNames = {
            'Summer': 'summer',
            'Winter': 'winter',
            'All_Season': 'all-year'
        }

        const documentSignedSubDeliveryTires = new Item({
            id: templateItemIds.signedBOS.deliveryTires,
            type: itemTypes.subItem,

            icon: <Icon icon={ICONS.TIRE} color={'white'} />,
            iconColor: offer.Installed_Tires__c
                ? colors.succes
                : colors.error,

            title: 'main-timeline.BOS.sub.tires.title',
            subject: offer.Installed_Tires__c
                ? `main-timeline.BOS.sub.tires.${tireNames[offer.Installed_Tires__c]}`
                : 'main-timeline.BOS.sub.tires.no-tires'
        }); documentSigned.subItems.push(documentSignedSubDeliveryTires);


        // Secondary Tires
        // const documentSignedSubSecondaryTires = new Item({
        //     id: templateItemIds.signedBOS.secondaryTires,
        //     type: itemTypes.subItem,

        //     icon: <Icon icon={ICONS.TIRE} color={'white'} />,
        //     iconColor: offer.Extra_Tires__c
        //         ? colors.succes
        //         : colors.default,

        //     title: 'main-timeline.BOS.sub.extra-tires.title',
        //     subject:
        //         offer.Extra_Tires__c
        //             ? offer.Extra_Tires__c
        //             : 'main-timeline.BOS.sub.extra-tires.unavailable'
        // }); documentSigned.subItems.push(documentSignedSubSecondaryTires);


        // Towbar
        // const documentSignedSubTrailerHitch = new Item({
        //     id: templateItemIds.signedBOS.trailerHitch,
        //     type: itemTypes.subItem,

        //     icon: <Icon icon={ICONS.HITCH} color={'white'} />,
        //     iconColor: offer.Towbar__c
        //         ? colors.succes
        //         : colors.default,

        //     title: 'main-timeline.BOS.sub.towbar.title',
        //     subject: `main-timeline.BOS.sub.towbar.${offer.Towbar__c ? 'on' : 'off'}`,
        // }); documentSigned.subItems.push(documentSignedSubTrailerHitch);


        // License plate
        const documentSignedSubLicensePlate = new Item({
            id: templateItemIds.signedBOS.licensePlates,
            type: itemTypes.subItem,

            icon: <Icon icon={ICONS.LICENSE_PLATE} color={'white'} />,
            iconColor: offer.EU_Plates__c
                ? colors.succes
                : colors.default,

            title: 'main-timeline.BOS.sub.license-plate.title',
            subject: `main-timeline.BOS.sub.license-plate.${offer.EU_Plates__c ? 'on' : 'off'}`
        }); documentSigned.subItems.push(documentSignedSubLicensePlate);

        template.main.push(documentSigned);





        // Registration doc
        // If theres a trade in vehicle:
        if (offer.Tradein__c) {
            // Do stuff, including possibility to upload files
            const tradein = offer.Tradein__r;


            /* 
                Only check outstanding debt if the vehicle has been checked and a debt value has been set
                OR if the vehicle hasn't been checked yet
            */
            const shouldCheckDebt = (
                tradein.TinglysningCheckDate__c != null &&
                tradein.TinglysningCheckValue__c != null
            ) || (
                    tradein.TinglysningCheckDate__c == null &&
                    tradein.TinglysningCheckValue__c == null
                );

            let vehicleName = tradein && (tradein.Make__c || tradein.Model__c)
                ? `${tradein.Make__c} ${tradein.Model__c}`
                : 'main-timeline.registration.unknown-vehicle';


            // Check status of registration doc, if missing, allow upload
            const reg = offer.Tradein__r.Registration_Doc__c;
            const debtStatus = offer.Tradein__r.Outstanding_Debt_Doc__c;
            // For the different scenarios, we set icon color, title and if needed, pulse.
            let regTimelineItemColor, regIconColor, regSubject;
            let regPulse = pulseTypes.normal;

            /* 
                Set Registration Sub-Timeline Item's color. 
                If debt should be checked, both docs need to have been checked, show green.

                If debt shouldb't be checked, only need reg to be checked
            */
            if (shouldCheckDebt === true) {
                if (reg === 'OK' && (debtStatus === 'OK' || debtStatus === 'NA')) { regTimelineItemColor = colors.success; }
                else if (reg === 'Uploaded' || debtStatus === 'Uploaded') { regTimelineItemColor = colors.warning; }
                else { regTimelineItemColor = colors.error; }
            } else if (shouldCheckDebt === false) {
                if (reg === 'OK') { regTimelineItemColor = colors.success; }
                else if (reg === 'Uploaded') { regTimelineItemColor = colors.warning; }
                else { regTimelineItemColor = colors.error; }
            }


            switch (reg) {
                case 'OK': case 'Received': {
                    regIconColor = colors.succes;
                    regSubject = 'main-timeline.registration.status.ok'; break;
                }
                case 'Uploaded': {
                    regIconColor = colors.warning;
                    regSubject = 'main-timeline.registration.status.uploaded'; break;
                }
                case 'Missing': case 'Requested': case null: case undefined:
                    regIconColor = colors.error;
                    regPulse = pulseTypes.high;
                    regSubject = 'main-timeline.registration.status.missing'; break;
                default: break;
            }

            const tradeInVehicleItem = new Item({
                id: templateItemIds.tradeinRegistration.id,
                type: itemTypes.itemWithTimeline,

                icon: <Icon icon={ICONS.TRADE_IN_VEHICLE} color={'white'} />,
                iconColor: regTimelineItemColor,

                pulse: regPulse,

                title: 'main-timeline.registration.title',
                subject: vehicleName,
            });

            // Sub Items
            // 

            // Registration Item
            // NEW TEXT
            const tradeInVehicleItemRegistration = new Item({
                id: templateItemIds.tradeinRegistration.registration,
                type: (!reg || reg === 'Missing' || reg === 'Requested')
                    ? itemTypes.fileUpload
                    : itemTypes.subItem,

                fileType: 'Registreringsattest', // used to set update reg_doc status in salesforce 
                // fileRelatedId: offer.Tradein__c,
                fileRelatedId: offer.Id,
                ownerId: offer.OwnerId,
                
                icon: <DescriptionIcon style={{ color: 'white' }} />,
                iconColor: regIconColor,

                uploadButtonLabel: 'main-timeline.registration.sub.doc.btnLabel',
                addFileLabel: 'main-timeline.registration.sub.doc.addFile',
                confirmUploadLabel: 'main-timeline.registration.sub.doc.confirmUpload',
                maxUploadCount: 5,
                maxUploadLabel: 'main-timeline.registration.sub.doc.maxUpload',
                title: 'main-timeline.registration.sub.doc.title',
                subject: regSubject
            }); tradeInVehicleItem.subItems.push(tradeInVehicleItemRegistration);



            /* 
                Outstanding debt
                Only show outstanding debt upload button if the vehicle has been checked and a debt value has been set
                or if the vehicle hasn't been checked

                Fields being referenced:
                    Tradein__r.TinglysningCheckDate__c
                    Tradein__r.TinglysningCheckValue__c
            */

            if (shouldCheckDebt) {
                let debtSubject, debtIconColor;
                switch (debtStatus) {
                    case 'OK': case 'Modtaget':
                        debtSubject = 'main-timeline.debt.sub.document.ok';
                        debtIconColor = colors.success;
                        break;
                    case 'Uploaded':
                        debtSubject = 'main-timeline.debt.sub.document.uploaded';
                        debtIconColor = colors.warning;
                        break;
                    case 'Missing': case 'Bestilt': case null: case undefined:
                        debtSubject = 'main-timeline.debt.sub.document.missing';
                        debtIconColor = colors.error;
                        break;
                    default: break;
                }

                // If status is 'Ok' or 'Uploaded', show normal subitem, else, file-upload item
                const outstandingDebtFileItem = new Item({
                    id: templateItemIds.tradeinOutstandingDebt.fileUpload,
                    type: (!debtStatus || debtStatus === 'Missing' || debtStatus === 'Bestilt')
                        ? itemTypes.fileUpload
                        : itemTypes.subItem,

                    fileType: 'Restgældsdokument',
                    // fileRelatedId: offer.Tradein__c,
                    fileRelatedId: offer.Id,
                    ownerId: offer.OwnerId,

                    icon: <DescriptionIcon style={{ color: 'white' }} />,
                    iconColor: debtIconColor,

                    uploadButtonLabel: 'main-timeline.debt.sub.document.btnLabel',
                    addFileLabel: 'main-timeline.debt.sub.document.addFile',
                    confirmUploadLabel: 'main-timeline.debt.sub.document.confirmUpload',
                    maxUploadCount: 5,
                    maxUploadLabel: 'main-timeline.debt.sub.document.maxUpload',
                    title: 'main-timeline.debt.sub.document.title',
                    subject: debtSubject
                }); tradeInVehicleItem.subItems.push(outstandingDebtFileItem);
            }


            // const tradeInVehicleItemKeys = new Item({
            //     id: templateItemIds.tradeinRegistration.keys,
            //     type: itemTypes.subItem,

            //     icon: <IoKeySharp style={{ color: 'white', height: 24, width: 24 }} />,
            //     iconColor: colors.default,

            //     title: 'main-timeline.registration.sub.key.title',
            //     subject: offer.Tradein__r.Tradein_Carkeys__c
            //         ? offer.Tradein__r.Tradein_Carkeys__c
            //         : 'main-timeline.registration.sub.empty'
            // }); tradeInVehicleItem.subItems.push(tradeInVehicleItemKeys);


            // const tradeInVehicleItemServiceBook = new Item({
            //     id: templateItemIds.tradeinRegistration.servicebook,
            //     type: itemTypes.subItem,

            //     icon: <GoBook style={{ color: 'white', height: 24, width: 24 }} />,
            //     iconColor: colors.default,

            //     title: 'main-timeline.registration.sub.service.title',
            //     subject: offer.Tradein__r.Tradein_Servicebook__c
            //         ? offer.Tradein__r.Tradein_Servicebook__c
            //             ? 'main-timeline.registration.sub.service.complete'
            //             : 'main-timeline.registration.sub.service.incomplete'
            //         : 'main-timeline.registration.sub.empty'
            // }); tradeInVehicleItem.subItems.push(tradeInVehicleItemServiceBook);


            // const tradeInVehicleItemTires = new Item({
            //     id: templateItemIds.tradeinRegistration.tires,
            //     type: itemTypes.subItem,

            //     icon: <Icon icon={ICONS.TIRE} color={'white'} />,
            //     iconColor: colors.default,

            //     title: 'main-timeline.registration.sub.tires.title',
            //     // Reuse BOS translations
            //     subject: offer.Tradein__r.Installed_Tires__c
            //         ? `main-timeline.BOS.sub.tires.${tireNames[offer.Tradein__r.Installed_Tires__c]}`
            //         : 'main-timeline.BOS.sub.tires.no-tires'
            // }); tradeInVehicleItem.subItems.push(tradeInVehicleItemTires);


            // const tradeInVehicleItemExtraTires = new Item({
            //     id: templateItemIds.tradeinRegistration.extra_tires,
            //     type: itemTypes.subItem,

            //     icon: <Icon icon={ICONS.TIRE} color={'white'} />,
            //     iconColor: colors.default,

            //     title: 'main-timeline.registration.sub.extra-tires.title',
            //     // Reuse BOS translations
            //     subject: offer.Tradein__r.Extra_Tires__c
            //         ? `main-timeline.BOS.sub.tires.${tireNames[offer.Tradein__r.Extra_Tires__c]}`
            //         : 'main-timeline.BOS.sub.tires.no-tires'
            // }); radeInVehicleItem.subItems.push(tradeInVehicleItemExtraTires);




            const tradeInVehicleItemInfo = new Item({
                id: templateItemIds.tradeinRegistration.info,
                type: itemTypes.subItem,

                icon: <InfoIcon style={{ color: 'white' }} />,
                iconColor: colors.default,

                title: 'main-timeline.registration.sub.info.title',
                subject: 'main-timeline.registration.sub.info.message'
            }); tradeInVehicleItem.subItems.push(tradeInVehicleItemInfo);

            template.main.push(tradeInVehicleItem);
        }







        /*  
            PAYMENT

            There's many different scenarios:
                - Tradein exists
                    - There's Tradein debt -> "can't calculate payment sum until you upload correct debt document"
                    - There's no Tradein debt -> show "0" as debt
                - Tradein doesn't exist
                    - Just pay whats on the Bill Of Sale

                modal with a table inside. Link/btn to open this is in the main box
            
            Payment is due 2 days before delivery.
        */

        const paymentDueDate = new Date(correctedDeliveryDate);
        paymentDueDate.setDate(paymentDueDate.getDate() - 2);

        // Paymen has been made if ecc = 64
        const paymentIsMade = offer.Vehicle__r && offer.Vehicle__r.ECC__c === 64 ? true : false;

        // Payment is late if due date has been passed and payment hasn't been made
        const paymentIsLate = paymentDueDate <= new Date() && !paymentIsMade;

        /* 
            Create Payment Info
                - No tradein
                - Tradein without debt
                - Tradein with debt
        */

        // We only show the text on mobile, since we render the overview in a modal and need a way to trigger it (the link in the subject)
        const paymentMainSubject = isMobile ? (<a style={{ color: '#ffb307' }} href={anchors.paymentOverviewAnchor}></a>) : null;

        const paymentItem = new Item({
            id: templateItemIds.payment.id,
            type: itemTypes.mainItem,

            icon: <CreditCardIcon style={{ color: 'white' }} />,
            iconColor: paymentIsLate
                ? colors.error
                : paymentIsMade
                    ? colors.succes
                    : colors.defualt,

            pulse: paymentIsLate
                ? pulseTypes.high
                : null,

            // main: {
            //     title: 'main-timeline.payment.title',
            //     subject: paymentMainSubject,
            //     i18nKey: 'pre_delivery:main-timeline.payment.subject'
            // },

            offset: {
                title: paymentIsLate
                    ? 'main-timeline.payment.missing'
                    : paymentIsMade
                        ? 'main-timeline.payment.done'
                        : 'main-timeline.payment.sub-title',
                subject: (new Date(paymentDueDate).toLocaleDateString('en-dk').replace(/\//g, '-'))
            },
        }); template.main.push(paymentItem);






        // Delivery
        const deliveryDate = new Date(offer.Handover_Start__c);

        // Wheter or not vehicle should have been delivered
        const delivered = deliveryDate < new Date() ? true : false
        const deliveryItem = new Item({
            id: templateItemIds.delivery.id,
            type: itemTypes.mainItem,

            icon: <IoKeySharp style={{ color: 'white', height: 24, width: 24 }} />,
            iconColor: delivered
                ? colors.succes
                : colors.default,

            main: {
                title: 'main-timeline.delivery.location.title',
                subject: (<a style={{ color: '#ffb307' }} href={locations[offer.Site__c]} target="_blank" rel="noreferrer">{offer.Site__c}</a>),
                i18nKey: 'pre_delivery:main-timeline.delivery.location.subject'
            },
            offset: {
                title: 'main-timeline.delivery.title',
                subject: (new Date(deliveryDate).toLocaleString('en-dk', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(/\//g, '-'))
            },
        }); template.main.push(deliveryItem);


        // Thank you
        const thankYouItem = new Item({
            id: 'thankYou',
            type: itemTypes.thankYou,
        }); template.main.push(thankYouItem);





        // const complaintItem = new Item({
        //     id: templateItemIds.complaint.id,
        //     type: itemTypes.mainItem,

        //     icon: <WarningIcon style={{ color: 'white' }} />,
        //     iconColor: colors.default,
        //     disabled: now < deliveryDate,

        //     main: {
        //         title: 'Hvordan?',
        //         subject:
        //             <i>Benyt dig af kontakt fomularen nede i højre hjørne</i>
        //     },
        //     offset: {
        //         title: 'Reklamation',
        //         subject: ''
        //     },
        // }); template.main.push(complaintItem);

        // Set disabled connectors - if the item is set to disabled, grey out the previous item's timeline connector
        template.main.forEach((item, index) => {
            if (item.disabled === true && index > 0) {
                template.main[index - 1].connectorDisabled = true;
            }
        })
    } catch (error) {
        console.log('Error Creating Template', error);
        rollbar.critical("Error Creating Template", { error, offer });
    }


    return template;
}
