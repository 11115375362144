import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'

// I18n
import { withTranslation } from "react-i18next";

// Button
import ConfirmButton from './ConfirmImages';

// API
import { setAutoproffReady, getFields } from '../../../../api/vehicleValuation';



class ConfirmImages extends Component {

    state = {
        loading: false,
        loadingSuccess: null,
        beenConfirmed: null,
        loaded: false
    }

    async componentDidUpdate() {
        const { loaded } = this.state;
        const { confirmImages, t, sfId, rollbar } = this.props;

        if (sfId && confirmImages && !loaded) {
            this.setState({ loading: true, loaded: true });

            try {
                // Get fields and check 'AutoproffReady__c' to see if user already has confirmed images
                console.log('getFields');
                const { success, resp } = await getFields(sfId);
                console.log('resp', resp);
                if (!success) {
                    this.setState({ loading: false, loadingSuccess: false });
                    throw new Error(`Couldn't get confirmImages button state - ${JSON.stringify(resp)}`);
                }

                // If the user has already confirmed images
                let beenConfirmed = false;
                if (resp.data && resp.data.fields && resp.data.fields.AutoproffReady__c) { beenConfirmed = true; }

                this.setState({
                    loading: false,
                    loadingSuccess: null,
                    beenConfirmed
                });

            } catch (error) {
                console.log('err', error);
                // Reset button
                this.setState({ loading: false, loadingSuccess: false })

                // rollbar
                rollbar.error("Couldn't get confirmImages button state", { sfId });

                // Alert user
                alert(t('confirm-images.error-occured'));
            }
        }
    }



    confirmImages() {
        const { loading, beenConfirmed, loadingSuccess } = this.state;
        const { completedAngles, t, sfId, rollbar } = this.props;
        const requiredImages = this.props.orgAngles.filter(angle => angle.required === true);
        // Only allow button click if we aren't loading and we haven't confirmed before
        if (
            (loading && loading !== false) ||
            (beenConfirmed && beenConfirmed !== false) ||
            (loadingSuccess && loadingSuccess === false) ||
            (completedAngles && completedAngles.length < requiredImages.length)
        ) { return; }


        this.setState({ loading: true });

        try {
            console.log('setAutoproffReady');
            // Update document
            setAutoproffReady({ sfId })
                .then(({ success, resp }) => {
                    if (success) {
                        this.setState({ loading: false, loadingSuccess: true }); // Show the user the succes

                        // Wait, then reset view
                        setTimeout(() => {
                            this.setState({ loading: false, loadingSuccess: null, beenConfirmed: true });
                        }, 2000);
                    } else { throw new Error('Autoproff Req wasnt successful'); }
                })
                .catch(err => {
                    console.log('err', err);
                    // Reset button
                    this.setState({ loading: false, loadingSuccess: false })
                    // Alert user
                    alert(t('confirm-images.error-occured'));
                    // rollbar
                    rollbar.error("Couldn't Set Autoproff Ready", { sfId });
                })



        } catch (err) {
            console.log('err', err);
            // // Alert user
            alert(t('confirm-images.error-occured'));
            // rollbar
            rollbar.error("Couldn't Set Autoproff Ready", { sfId });
            // Reset button
            this.setState({ loading: false, loadingSuccess: false })
        }


    }

    render() {
        const { children, confirmImages } = this.props;
        if (confirmImages === true) {
            return (
                <div style={{ width: '100%', marginBottom: '1em' }}>
                    <ConfirmButton {...this.state} confirmImages={this.confirmImages.bind(this)} />
                    {children}
                    <ConfirmButton {...this.state} confirmImages={this.confirmImages.bind(this)} />
                </div>
            )
        } else return children;
    }

}

const mapStateToProps = ({ rollbar }) => {
    return { rollbar }
}

const mapDispatchToProps = () => {
    return {}
}

export default
    compose(
        withTranslation('vehicle_valuation'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(ConfirmImages)