
export default function Item({
    id, type, icon, iconColor, title, subject,

    pulse = null, fileType = null, disabled = false,
    main = { title: null, subject: null, i18nKey: null },
    offset = { title: null, subject: null, i18nKey: null },
    fileRelatedId = null,
    i18nKey = null,
    uploadButtonLabel = null,
    addFileLabel = null,
    confirmUploadLabel=null,
    maxUploadCount=null,
    maxUploadLabel=null,

    ownerId = null,
}) {

    this.id = id;
    this.type = type;

    // Icon
    this.icon = icon;
    this.iconColor = iconColor;

    // Text Shown - with Timeline
    this.title = title;
    this.subject = subject;

    // Is i18nKey used in places where a subject (usually) is more complex than a i18n-next translation can be, and we have to use the <Trans> component
    this.i18nKey = i18nKey;
    // Text Shown - main Item
    this.main = main;
    this.offset = offset;


    // Tooltip 
    this.tooltip = null;

    // For 'type' = 'itemWithTimeline'
    this.subItems = [];

    // Displays pulse effect for attention
    this.pulse = pulse;

    // Used to specify the type of file being uploaded to Salesforce - 'Restgæld', 'Registreringsattest' etc.
    this.fileType = fileType;
    this.fileRelatedId = fileRelatedId;
    this.uploadButtonLabel = uploadButtonLabel;
    this.addFileLabel = addFileLabel;
    this.confirmUploadLabel = confirmUploadLabel;
    this.maxUploadCount = maxUploadCount;
    this.maxUploadLabel = maxUploadLabel;
    this.maxUploadCount = maxUploadCount;
    this.maxUploadCount = maxUploadCount;

    // Used to disable a feature if it isn't to be made avaiable to the user, but still shown
    this.disabled = disabled;
    this.connectorDisabled = null; // We loop through the items and set this if the next item is disabled.    

    this.ownerId = ownerId;
}