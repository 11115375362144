import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// I18n
import { withTranslation } from "react-i18next";
import Subject from './Subject';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import { setOffer, setTemplate } from '../../../actions'
import { uploadDocument, getOffer } from '../../../api/offer';

import { createTemplate } from '../Template';
import { colors } from '../Constants';


import jsPDF from 'jspdf';
import { getBase64Strings } from 'exif-rotate-js/lib';




class FileUploadItem extends React.Component {

    state = {
        // For uploading images to the frontend
        loading: false,

        // For uploading images to the backend
        uploading: false,
        uploadSucces: null,
        files: []
    }


    async uploadedFiles(e) {
        let { rollbar, t, item } = this.props;

        // Alert the user if they've uploaded too many files
        if (this.state.files.length + e.target.files.length > item.maxUploadCount) {
            alert(t(item.maxUploadLabel, { count: item.maxUploadCount }));
            e.value = ''; return;
        }

        // Set loading
        this.setState({ loading: true });

        try {
            // 'getBase64Strings' returns a b64 of a properly rotated image (with regards to exif rotations. They are apparently very hard to deal with....)
            const data = await getBase64Strings(e.target.files, { maxSize: 1280 });

            // Add files to state
            let files = this.state.files;
            files = files.concat(data);
            this.setState({ files, loading: false });


            // If only 1 file is allowed to be uploaded - create pdf right away
            if (item.maxUploadCount === 1) { this.uploadPDF(); }

        } catch (err) {
            // Alert user & rollbar
            alert(t('file-upload.error'));
            this.setState({ loading: false, uploadSucces: false });
            rollbar.error('FileReader in FileUploadItem Broke', { err });

            // Reset button
            setTimeout(() => { this.setState({ loading: false, uploadSucces: null }) }, 1500);
        } finally { e.value = ''; }
    }


    createPDF() {
        const files = this.state.files;
        const doc = new jsPDF();

        files.forEach((file, index) => {
            const imgProps = doc.getImageProperties(file);
            if (index === 0) { doc.deletePage(1); }

            // Change the orientation to match the proportions - else jspdf rotates it to be portrait
            const orientation = imgProps.width > imgProps.height ? 'l' : 'p';
            doc.addPage([imgProps.width - 1, imgProps.height - 1], orientation);
            doc.addImage(file, 'JPEG', 0, 0, imgProps.width, imgProps.height, null, 'MEDIUM', 0);
        });

        // doc.save('test.pdf');
        const datauri = doc.output('datauristring');
        const base64 = datauri.substring(datauri.indexOf('base64,') + 7);
        return base64;
    }


    async uploadPDF() {
        const { offer, rollbar, t, item, setTemplate, setOffer } = this.props;

        // Confirm upload, and ensure images arent being uploaded
        if (this.loading || this.uploading || !window.confirm(t(item.confirmUploadLabel))) { return; }

        console.log('uploadPDF');
        this.setState({ uploading: true });

        const files = this.state.files;

        // Don't create a pdf if only 1 image was uploaded
        let uploadData;
        if (files.length > 1) {
            uploadData = this.createPDF();
        } else {
            const file = this.state.files[0];
            const base64 = file.substring(file.indexOf('base64,') + 7);
            uploadData = base64;
        }

        const body = {
            offerId: offer.Id,
            data: uploadData,
            documentType: item.fileType,
            title: item.fileType + (files.length > 1 ? '.pdf' : '.jpeg'),
            relatedRecordId: item.fileRelatedId,
            ownerId: item.ownerId
        };

        // Upload document
        try {
            // Upload the document (pdf/image)
            const uploadResponse = await uploadDocument(body);
            if (!uploadResponse.success) { console.log('resp', uploadResponse); throw new Error(uploadResponse); }

            // Show the user the succes
            this.setState({ uploading: false, uploadSucces: true });

            // If we were successful uploading document, re-set template after fetching the new offer (get new offer docs statuses)
            const offerResponse = await getOffer(offer.ADT_OfferId__c);
            const offerResp = offerResponse.resp;

            if (!offerResponse.success) { console.log('offerResp', offerResp); throw new Error(offerResp); }
            console.log('offer after upload', offerResp.data.offer);

            // Set new offer in redux
            setOffer(offerResp.data.offer);

            // Wait, then reset view
            setTimeout(() => {
                setTemplate(createTemplate(offerResp.data.offer));
                this.setState({ uploading: false, uploadSucces: null, files: [] });
            }, 2000)

        } catch (error) {
            // Alert user
            console.log('err', error);
            alert(t('file-upload.error'));
            rollbar.error("Couldn't Upload document", { error, offer });

            // Reset button
            this.setState({ uploading: false, uploadSucces: false });
        }

    }


    render() {
        const { loading, uploadSucces, uploading, files } = this.state;
        const { children, item, isMobile, isLast, t } = this.props;
        const { title, subject, i18nKey, uploadButtonLabel, addFileLabel } = item;

        // Needs to be a unique htmlFor, otherwise, 
        // the button doesn't know which input to register for.
        // Can lead to unexpected input behavior;
        const htmlFor = `${item.fileType}-input`;


        return (
            <TimelineItem style={{ minHeight: isLast ? 'fit-content' : 62 }}>
                <TimelineOppositeContent style={{ display: 'none' }}>
                </TimelineOppositeContent>

                {children}

                <TimelineContent style={{
                    textAlign: 'left',
                    padding: isMobile ? '6px 0px 0px 3px' : 'auto',
                    overflow: 'auto',
                    marginBottom: '1.25em',
                }}>
                    <Typography variant="body2" style={{ color: 'lavender', letterSpacing: '0.75px' }}>
                        {t(title)}
                    </Typography>

                    <Typography variant="caption" style={{ color: 'lavender', letterSpacing: 1 }}>
                        <Subject content={subject} i18nKey={i18nKey} /> <br />
                        {this.state.files.length > 0 && (
                            <b>{t('main-timeline.debt.sub.document.docCount', { count: this.state.files.length })}</b>
                        )}
                    </Typography>

                    {/*
                        If user can is allowed to upload multipel files, show 'upload' button until limit has been reached.
                        Else, if user can only upload 1 file, show only when no files have been uploaded
                    */}
                    {(
                        (files.length === 0 && item.maxUploadCount === 1) ||
                        (files.length <= item.maxUploadCount && item.maxUploadCount > 1)
                    ) && (
                            <div>
                                <input
                                    onChange={this.uploadedFiles.bind(this)}
                                    accept="image/jpeg,image/png,application/pdf" multiple disabled={!(uploading === false && uploadSucces === null)}
                                    id={htmlFor} type="file" style={{ display: 'none' }}
                                />

                                <label htmlFor={htmlFor} style={{ display: 'flex', marginTop: '0.5rem' }}>
                                    <Button
                                        disabled={!(uploading === false && uploadSucces === null)}
                                        variant="contained" color="primary" component="span"
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: colors.default,
                                            width: '100%', maxWidth: 250
                                        }}
                                    >
                                        {loading === false && (t(addFileLabel))}
                                        {loading === true && (<CircularProgress size={24} style={{ color: 'white' }} />)}
                                    </Button>
                                </label>
                            </div>
                        )}

                    {files.length > 0 && (
                        <Button
                            onClick={async () => this.uploadPDF()} variant="contained" disabled={uploading}
                            color="primary" component="span" style={{ backgroundColor: colors.AMYellow, width: '100%', maxWidth: 250, marginTop: '10px', textAlign: 'center' }}
                        >
                            {uploading === false && uploadSucces === null && (t(uploadButtonLabel))}
                            {uploading === true && uploadSucces === null && (<CircularProgress size={24} style={{ color: 'white' }} />)}
                            {uploading === false && uploadSucces === true && (<CheckCircleIcon size={24} />)}
                            {uploading === false && uploadSucces === false && (<WarningIcon size={24} />)}
                        </Button>
                    )}

                    {/* <br /> */}
                </TimelineContent>
            </TimelineItem>
        )
    }
}


const mapStateToProps = ({ offer, isMobile, rollbar }) => {
    return { offer, isMobile, rollbar };
}


const mapDispatchToProps = (dispatch) => {
    return {
        setOffer: offer => dispatch(setOffer(offer)),
        setTemplate: template => dispatch(setTemplate(template))
    }
}

export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(FileUploadItem)

