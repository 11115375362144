
const base = '/api/';

const request = (endpoint, method, code, body = null) => {
    return new Promise((resolve, reject) => {
        try {
            const xmlHttp = new XMLHttpRequest();
            xmlHttp.withCredentials = true;
            xmlHttp.onreadystatechange = function () {
                if (xmlHttp.readyState === 4) {
                    if (xmlHttp.status !== null) {
                        if (xmlHttp.status === 401) {
                            console.log('Not authorized - redirecting...');
                            window.location.href = '/pre-delivery/login';
                        } else {
                            resolve({
                                success: xmlHttp.status === code,
                                resp: xmlHttp.response ? JSON.parse(xmlHttp.response) : {}
                            });
                        }
                    }
                }
            }
            xmlHttp.open(method, base + endpoint, true); // true for asynchronous 

            xmlHttp.setRequestHeader("Content-Type", "application/json");
            xmlHttp.send(JSON.stringify(body));
        } catch (error) {
            reject(error)
        }
    })
}

export {
    request
}