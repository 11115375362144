import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './Seperator.css';

// I18n
import { withTranslation } from "react-i18next";

import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

import { setOpenTooltip } from '../../../../actions'

import Dot from './Dot'


function RefTooltip({ tooltip, id, openTooltip, isMobile, children }) {
    // If theres a tooltip, render it
    if (tooltip && tooltip.title && tooltip.subject) {
        return (
            <Tooltip placement="top" arrow open={id && openTooltip === id ? true : isMobile ? false : undefined} title={
                <React.Fragment>
                    <Typography>{tooltip.title}</Typography>
                    <div style={{ fontSize: 'small', fontWeight: 300 }}>{tooltip.subject}</div>
                </React.Fragment >
            }>
                {/* There has to be a div to hold ref from the tooltip */}
                <div> {children} </div>
            </Tooltip >
        )
    } else return (children)
    // if not, render the children
}



class Seperator extends Component {

    state = {
        instructionRef: null,
        popoverOpen: true // only visible first time user accesses site
    }

    onClick(e) {
        e.stopPropagation(); // stop event bubbling
        const { isMobile, handleOpenTooltip, item, btnClick } = this.props
        // only fire tooltip func if its mobile, id is set and its a mainbutton (if btnClick is set)
        if (isMobile && item.id && !btnClick && !item.disabled) {
            handleOpenTooltip(item.id);
        }
    }

    componentDidMount() {

        // The popover and icon itself needs access to the ref
        const instructionRef = React.createRef();
        this.setState({ instructionRef });
    }

    closePopover(e) {
        e.stopPropagation();
        this.setState({ popoverOpen: false });

        // Show the user the expanded section.
        if (this.props.btnClick) { this.props.btnClick() }
    }


    render() {
        const {
            btnClick, item, openTooltip, isMobile,
            isLast = false, t
        } = this.props;

        const {
            iconColor = '#bdbdbd', pulse = false,
            icon, id, tooltip, disabled, connectorDisabled
        } = item;

        const { instructionRef, popoverOpen } = this.state;


        // On mobile, we enable 'show-on-click', since the 'hover' doesn't work on mobile platform
        return (
            <TimelineSeparator onClick={this.onClick.bind(this)}>

                <RefTooltip tooltip={tooltip} id={id} openTooltip={openTooltip} isMobile={isMobile}>
                    {(icon && (
                        // Dont fire clickhandler if disabled
                        <Dot backgroundColor={iconColor} clickHandler={btnClick} pulse={pulse} disabled={disabled}>

                            {/* Create/use ref for the popover */}
                            <div ref={instructionRef} style={{ display: 'flex' }}>
                                {icon}
                            </div>

                            {icon.props.showInstructions && instructionRef && instructionRef.current && (
                                //* Shown the first time a user enters the site */
                                <Popover
                                    open={popoverOpen}
                                    classes={{ root: 'root' }}
                                    anchorEl={instructionRef.current}
                                    onClose={this.closePopover.bind(this)}
                                    PaperProps={{ classes: { root: 'popover-paper' } }}
                                    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <Box p={2}>
                                        {/* <Typography>Du kan klikke på nogle af ikonerne for at se mere info...</Typography> */}
                                        <Typography>{t('main-timeline.seperator.click-icons-message')}</Typography>
                                    </Box>
                                </Popover>
                            )}
                        </Dot>
                    )) || <div></div>}

                </RefTooltip>

                { !isLast && (<TimelineConnector style={{ backgroundColor: '#ffb307', opacity: connectorDisabled ? 0.4 : 1 }} />)}

            </TimelineSeparator>
        )
    }
}

const mapStateToProps = ({ isMobile, openTooltip }) => {
    return { isMobile, openTooltip }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleOpenTooltip: tooltip => dispatch(setOpenTooltip(tooltip)),
    }
}

export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(Seperator)

