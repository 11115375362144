import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'

// I18n
import { withTranslation } from "react-i18next";

// Redux
import {
    setIsAuthenticated,
    setRollbarConfig
} from '../../actions'

// Error Logging
import rollbar from '../../rollbar';

import {
    Route,
    Switch,
    Redirect,
    BrowserRouter as Router,
} from 'react-router-dom';

// Components
import { LanguageSelectors } from './LanguageSelectors';
// import App from '../../App';
import Login from '../Login/Login';
import PreDelivery from '../PreDelivery/PreDelivery';
import VehicleImageValuation from '../VehicleImageValuation/VehicleImageValuation';

// Import css
import styles from './MainRouter.module.css';
import loginStyles from '../Login/Login.module.css';
import { Button, Typography } from '@material-ui/core';

// import CaseBot from '../CaseBot/CaseBot';

// Api
import { check } from '../../api/auth';

function AuthRoute({ component: Component, authenticated, fallbackRoute, ...rest }) {
    return (
        <Route
            exact
            {...rest}
            render={(props) => {
                // if (authenticated === null) { return (<Redirect to={{ pathname: fallbackRoute, state: { from: props.location }, search: props.location.search }} />); }
                // else if (authenticated === true) { return <Component {...props} />; }
                if (authenticated === true) { return <Component {...props} />; }
                else if (authenticated === false) { return <Redirect to={{ pathname: '/pre-delivery/login', state: { from: props.location } }} />; }
            }}
        />
    )
}


class MainRouter extends Component {

    state = {
        showCookieConsent: false
    }

    cookieKey = 'COOKIE_CONSENT_LS_KEY';

    componentDidMount() {
        this.checkCookieConsent();

        // Sets rollbar to be accessible from redux
        this.props.setRollbarConfig(rollbar);

        const url = new URL(window.location.href);
        const offerId = url.searchParams.get("offerId");
        if (offerId) {

            check(offerId)
                .then(({ success, resp }) => {
                    console.log(`checked and ${success ? 'is authenticated' : 'is *NOT*  authenticated'}`, resp);
                    this.props.setIsAuthenticated(success);                   
                })
                .catch(err => {
                    console.log('err', err)
                    rollbar.error('Couldn\'t Check Validate Session', err)
                    this.props.setIsAuthenticated(false);
                });
        }
    }


    checkCookieConsent() {
        if (!window.localStorage.getItem(this.cookieKey)) {
            this.setState({ showCookieConsent: true });
        }
    }

    // Since we only do nescessary cookies, we just have to note, that the user has seen the consent
    setCookieConsent() {
        console.log(this.cookieKey);
        window.localStorage.setItem(this.cookieKey, true);
        this.setState({ showCookieConsent: false });
    }


    render() {
        const { authenticated, t } = this.props;
        const { showCookieConsent } = this.state;

        return (
            <Router>
                <div style={{ minHeight: 'calc(100vh - 3rem)' }}>
                    <Switch>
                        <Suspense fallback='loading'>
                            <Route exact path="/"> <Redirect to="/pre-delivery/login" /> </Route>
                            <Route path='/pre-delivery/login' render={(props) => <Login {...props} />} />
                            <AuthRoute path='/pre-delivery/' authenticated={authenticated} component={PreDelivery} fallbackRoute='/pre-delivery/login' />

                            <Route path='/vehicle-valuation/' render={(props) => <VehicleImageValuation {...props} />} />
                            <Route path='/valuation/' render={(props) => <VehicleImageValuation {...props} />} />
                        </Suspense>
                    </Switch>
                </div>


                {showCookieConsent && (
                    <div className={styles['cookie-consent']}>
                        <div className={styles['consent-message']}>
                            <Typography variant="h6" gutterBottom>
                                {t('main:cookie.consent-title')}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('main:cookie.consent-message')}
                            </Typography>
                        </div>

                        <Button variant="contained" color="primary" style={{ height: 'fit-content', padding: '0.75rem' }} className={loginStyles.btn} onClick={this.setCookieConsent.bind(this)}>
                            Ok
                        </Button>
                    </div>
                )}

                {/* <CaseBot /> */}

                {/* <App/> */}

                <Typography variant="body2" style={{ padding: '3rem', paddingTop: 0 }}>
                    Copyright © Andersen & Martini. All Rights Reserved |&nbsp;
                    <a href="https://am.dk/privatlivspolitik/" style={{ color: '#ce9209' }} target="_blank" rel="noreferrer">
                        {t('main:privacy-policy')}
                    </a>
                </Typography>

                <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
                    <LanguageSelectors />
                </div>
            </Router >
        )
    }

}

const mapStateToProps = ({ authenticated, rollbar }) => {
    return { authenticated, rollbar }
}


// export default MainRouter;
const mapDispatchToProps = (dispatch) => {
    return {
        setIsAuthenticated: authed => dispatch(setIsAuthenticated(authed)),
        setRollbarConfig: config => dispatch(setRollbarConfig(config)),
    }
}


export default
    compose(
        withTranslation(),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(MainRouter);

