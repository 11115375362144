import { request } from '../index';

const base = 'vehicle-valuation'

const getAngles = (sfId) => { return request(`${base}/images/${sfId}`, 'GET', 200); }
const getFields = (sfId) => { return request(`${base}/fields/${sfId}`, 'GET', 200); }

const uploadImage = (body) => { return request(`${base}/upload-image`, 'POST', 201, body); }
const setAutoproffReady = (body) => { return request(`${base}/autoproff-ready`, 'POST', 201, body); }


export {
    getAngles,
    getFields,

    uploadImage,
    setAutoproffReady,
}