import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

// I18n
import { withTranslation } from "react-i18next";

import { setOffer, setIsAuthenticated } from '../../actions';
import { login } from '../../api/auth';


import background from './assets/loginbackground.jpg';
import logo from './assets/AM.png';

// Import css
import styles from './Login.module.css';

import {
    Box,
    Button,
    TextField,
    Typography,
    InputAdornment,
    CircularProgress
} from '@material-ui/core';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';



class Login extends Component {

    state = {
        offerId: '',
        phone: '',

        errors: {},

        imgLoaded: false,

        toPreDelivery: false,
    }

    componentDidMount() {

        // Submit on enter
        window.addEventListener('keyup', this.enterListener);

        if (this.props.location && this.props.location.search) {
            // If the query contains phonenumber or offerId, set it 
            const params = queryString.parse(this.props.location.search);
            if (params.offerId) { this.setState({ offerId: params.offerId }); }

            // if (params.phone) {
            //     let phone = params.phone;
            //     if (phone.includes('+45')) { phone = phone.substring(3, phone.length); }
            //     this.setState({ phone });
            // }
        }
    }

    componentWillUnmount() {
        // Remove keyup "Enter" listener
        window.removeEventListener('keyup', this.enterListener);
    }

    onChange(e, type) {
        const v = e.target.value;
        const numbers = /^[0-9]+$/;

        if (v.match(numbers) || v.length === 0) {
            const state = this.state;
            state[type] = v
            this.setState(state);
        }
    }

    // Allows submit on Enter
    enterListener = (e) => { if (e.key === 'Enter') { this.submit(); } }

    validate() {
        const { offerId, phone } = this.state;
        const errors = {};
        let valid = true;

        // if (phone.length !== 6 || phone.includes('45')) {
        if (phone.length !== 8) {
            // let texts = [];
            // if (phone.includes('45')) texts.push("Må ikke indeholde '+45'");
            // if (phone.length !== 8) texts.push('Forkert længde,  ' + phone.length + '. Burde være 8');
            // if (phone.length !== 8) {
            // texts.push('Forkert længde,  ' + phone.length + '. Burde være 8');
            // }
            // errors.phone = { text: texts.join('. ') }

            // errors.phone = { text: 'Forkert længde,  ' + phone.length + '. Burde være 8' }
            errors.phone = { text: 'incorrect-phone-length', length: phone.length }
            valid = false;
        }
        if (offerId.length !== 7) {
            errors.offerId = { text: 'incorrect-BOS-length', length: offerId.length }
            valid = false;
        }

        this.setState({ errors });
        return valid;
    }

    submit() {
        // Validates fields
        if (!this.validate()) { console.log('not valid'); return; }
        const { offerId, phone } = this.state;

        this.setState({ loading: true });
        // Signs in
        login({ offerId, phone: '+45' + phone })
            .then(({ success, resp }) => {
                // Success
                if (success) {
                    // Clear errors and set offer 
                    if (resp.data.offer) {
                        this.props.setIsAuthenticated(true);
                        this.setState({ toPreDelivery: true, errors: {} });
                    } else {
                        console.log('login error - unathorized');

                        // Notify User
                        this.setState({ errors: { 'unathorized': { text: 'unautherized' } } });
                    }
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.rollbar.error('Couldn\'t Login', err, { offerId, phone });
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {
        const {
            offerId, phone, errors,
            imgLoaded, toPreDelivery,
            loading
        } = this.state;

        const {
            t
        } = this.props;

        const theme = createMuiTheme({
            palette: {
                primary: { main: '#ffb307' },
                secondary: { main: '#ffb307' },
            },
        });

        // 'Programatically' redirecting. Follows the react-router-dom v5 guidelines
        if (toPreDelivery === true) { return (<Redirect to={`/pre-delivery?offerId=${offerId}`} />) }

        return (
            // <div className="container">
            <div className={styles.container}>
                <img
                    // className="background-image" src={background} alt="background"
                    className={styles['background-image']} src={background} alt="background"
                    onLoad={() => this.setState({ imgLoaded: true })}
                    style={{ visibility: imgLoaded ? 'unset' : 'hidden' }}
                />

                <Grid item xs={12} sm={10} md={6} lg={4} xl={4} className={styles.center}>
                    <Paper boxShadow={10} className={styles.card} style={{ borderRadius: '10px', visibility: imgLoaded ? 'unset' : 'hidden' }}>
                        <ThemeProvider theme={theme}>
                            <img src={logo} style={{ width: '100%' }} alt="am-logo" />
                            <form>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        label={t('phone')} value={phone} id="phone" type='tel'
                                        // Styling
                                        style={{ marginTop: '2rem' }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">+45</InputAdornment> }}

                                        // Errors
                                        error={!!errors['phone']} // If there's an error
                                        helperText={
                                            errors['phone']
                                                ? t(errors['phone'].text, { length: errors['phone'].length })
                                                : ''
                                        }

                                        onChange={e => this.onChange(e, 'phone')}
                                    />

                                    <TextField
                                        label={t('BOS')} value={offerId} id="offerId"

                                        // Styling
                                        style={{ marginTop: '1.5rem' }}

                                        // Errors
                                        error={!!errors['offerId']} // If there's an error
                                        helperText={
                                            errors['offerId']
                                                ? t(errors['offerId'].text, { length: errors['offerId'].length })
                                                : ''
                                        }

                                        onChange={e => this.onChange(e, 'offerId')}
                                    />

                                    {errors['unathorized'] && (
                                        <Box color="error.main" style={{ marginTop: '1.5rem' }}>
                                            <Typography variant="subtitle1">
                                                {t(errors['unathorized'].text)}
                                            </Typography>
                                        </Box>
                                    )}

                                    <Button variant="contained" color="primary" disabled={loading} className={styles.btn}
                                        style={{ opacity: loading ? 0.7 : 1 }}
                                        onClick={() => this.submit()}
                                    >
                                        {t('sign-in')}
                                        {loading && (<CircularProgress size={24} style={{ position: 'absolute', color: 'black' }} />)}
                                    </Button>
                                </div>
                            </form>
                        </ThemeProvider>
                    </Paper>
                </Grid>
            </div >
        )
    }

}

const mapStateToProps = ({ rollbar }) => {
    return { rollbar }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOffer: offer => dispatch(setOffer(offer)),
        setIsAuthenticated: authed => dispatch(setIsAuthenticated(authed)),
    }
}

export default
    compose(
        withTranslation('login'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(Login)
