import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// I18n
import { withTranslation } from "react-i18next";

import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';

import logo from '../icons/AM.png';

import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import TimelineConnector from '@material-ui/lab/TimelineConnector';

import MainItem from './MainItem/MainItem';


class AMLogo extends React.Component {

    render() {
        const { offer, t } = this.props;

        return (
            <MainItem>
                <TimelineSeparator style={{ flexBasis: 'auto' }}>
                    <div style={{ width: '85%', maxWidth: 450, marginBottom: '0.75rem', marginLeft: 'calc(50% * -0.17)' }}>
                        <img src={logo} alt="am-logo" style={{ width: '100%' }} />

                        <div style={{ height: 0, width: '50%' }}>

                            <Typography variant="body2" style={{ display: 'flex' }}>
                                <DirectionsCarIcon style={{ height: '0.8em' }} />
                                {
                                    ((!offer.Vehicle__c || !offer.Vehicle__r.Make__c || !offer.Vehicle__r.Model__c) && (
                                        t('template.main-timeline.no-vehicle-data')
                                    )) || (
                                        offer.Vehicle__r.Make__c + ' ' + offer.Vehicle__r.Model__c
                                    )
                                }
                            </Typography>

                            <Typography variant="body2" style={{ display: 'flex' }}>
                                <PersonIcon style={{ height: '0.8em' }} />
                                {t('salesman')}
                                {offer.Owner && offer.Owner.Name && (offer.Owner.Name)}
                            </Typography>

                        </div>
                    </div>
                    <TimelineConnector style={{ backgroundColor: '#ffb307' }} />
                </TimelineSeparator>

            </MainItem>
        )
    }

}

const mapStateToProps = ({ isMobile }) => {
    return { isMobile }
}


const mapDispatchToProps = () => {
    return {}
}


export default
    compose(
        withTranslation('pre_delivery'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(AMLogo)

