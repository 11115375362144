import React from 'react';

// I18n
import { Trans, withTranslation } from "react-i18next";


// With i18n, our subjects have to be able to render more than just a i18n key
// Complex translation objects can be created with the <Trans> component, docs: https://react.i18next.com/latest/trans-component

class Subject extends React.Component {

    render() {

        const { content, i18nKey, t } = this.props;

        if (!content) { return null; }
        else if (typeof content === 'string' && content.includes('main-timeline.')) {
            // console.log('content',content);
            return <i>{t(content)}</i>;
        } else if (typeof content === 'string' && !content.includes('main-timeline.')) {
            return <i>{content}</i>;
        } else if (typeof content === 'object' && i18nKey) {
            return (
                <Trans i18nKey={i18nKey}>
                    {content}
                </Trans>
            );
        }

        return null;
    }
}

export default withTranslation('pre_delivery')(Subject);
