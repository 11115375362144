import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import imageCompression from 'browser-image-compression';

// I18n
import { withTranslation } from "react-i18next";

// Icons
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

// API
import { uploadImage } from '../../../../api/vehicleValuation';


class VehicleImageValuation extends Component {

    state = {
        loading: false,
        uploadSuccess: null,
    }



    async uploadImage(e) {
        let { rollbar, currentAngle, sfId, t } = this.props;
        let { loading, uploadSuccess } = this.state;
        if (loading !== false || uploadSuccess !== null) return;

        try {
            this.setState({ loading: true });
            const file = e.target.files[0];

            const reader = new FileReader();
            const self = this;

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };

            // Compress the file and them pass that to the reader.
            const compressedFile = await imageCompression(file, options);

            reader.readAsBinaryString(compressedFile);
            reader.onload = function () {
                const body = {
                    title: `${currentAngle.id}.jpg`, id: sfId,
                    data: btoa(reader.result) // base64 encoded image
                };

                // Update document
                uploadImage(body)
                    .then(({ success, resp }) => {
                        if (success) {
                            // Show the user the succes
                            self.setState({ loading: false, uploadSuccess: true });

                            // Reset the contents of file
                            // self.document.getElementById('file').value = '';
                            const fileUploadEl = document.getElementById('file');
                            if (fileUploadEl) { fileUploadEl.value = ''; };

                            // Wait, then reset view
                            setTimeout(() => {
                                self.setState({ loading: false, uploadSuccess: null });
                                self.props.uploaded();
                            }, 500);
                        } else {
                            throw new Error(`Error Uploading Image, ${JSON.stringify(resp)}`);
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                        throw err;
                    })

            };

            reader.onerror = function () {
                // Alert user
                alert(t('upload-images.error-occured'));
                console.log('reader.error', reader.error);
                // Rollbar
                rollbar.error('FileReader in FileUploadItem Broke', { err: reader.error });

                // Reset button
                self.setState({ loading: false, uploadSuccess: false });
                setTimeout(() => { self.setState({ loading: false, uploadSuccess: null }) }, 3000);
            };

        } catch (error) {
            console.log('err', error);
            // Alert user
            alert(t('upload-images.error-occured'));
            // rollbar
            rollbar.error("Couldn't Upload image", { error });
            // Reset button
            this.setState({ loading: false, uploadSucces: false });
        }

    }

    render() {
        const { loading, uploadSuccess } = this.state;
        const { isCurrentAngle, currentAngle, t } = this.props;
        const inputId = `file-${currentAngle.id}`;

        return (
            <div style={{ display: 'flex', margin: '0.5rem' }}>
                <input
                    type="file" name="file" id={inputId} className="inputfile"
                    accept="image/jpeg,image/png" multiple={false}
                    onChange={this.uploadImage.bind(this)}
                />

                <label htmlFor={inputId} className="button" style={{ fontSize: 12, textAlign: 'center' }}>
                    {loading === false && uploadSuccess === null && !isCurrentAngle && t('upload-images.button.upload')}
                    {loading === false && uploadSuccess === null && isCurrentAngle && t('upload-images.button.change')}
                    {loading === true && uploadSuccess === null && (<CircularProgress size={24} style={{ color: 'white' }} />)}
                    {loading === false && uploadSuccess === true && (<CheckCircleIcon size={24} />)}
                    {loading === false && uploadSuccess === false && (<WarningIcon size={24} />)}
                </label>
            </div>
        )
    }

}

const mapStateToProps = ({ authenticated, rollbar }) => {
    return { authenticated, rollbar }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default
    compose(
        withTranslation('vehicle_valuation'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(VehicleImageValuation);



