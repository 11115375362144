import React, { Component } from 'react';

// I18n
import { withTranslation } from "react-i18next";

// Import css
import styles from './ImageModal.module.css'


class ImageModal extends Component {

    state = {
        isHidden: true
    }

    src = () => {
        const { sfId, angle } = this.props;
        const d = new Date();
        const base = 'https://blobstorageam.blob.core.windows.net/vehicle-inspection';
        // Using 'ver' ensure we disable caching, so if a user uploads an image and clicks 'Se Billede', the user will se the new image
        // Allow caching for 'exemplary' images
        return `${base}/${sfId}/${angle.id}.jpg?${sfId !== 'exemplary' ? `ver=${d.getTime()}` : ''}`;
    }

    closeModal = () => { this.setState({ isHidden: true }) }
    openModal = () => { this.setState({ isHidden: false }) }

    render() {

        const { angle, children, t } = this.props;
        const { isHidden } = this.state;

        return (
            <div>
                <div onClick={this.openModal}>
                    {children}
                </div>

                { (!isHidden && (
                    <div className={styles.modal}>
                        {/* <!-- The Close Button --> */}
                        <span className={styles.close} onClick={this.closeModal}>&times;</span>

                        {/* <!-- Modal Content (The Image) --> */}
                        <img className={styles['modal-content']} alt={angle.title} src={this.src()} />

                        {/* <!-- Modal Caption (Image Text) --> */}
                        <div className={styles.caption}>{t(angle.title)}</div>
                    </div>
                ))}
            </div>
        )
    }

}



export default withTranslation('vehicle_valuation')(ImageModal)



