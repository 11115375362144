import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux'
import { compose } from 'redux'

// I18n
import { withTranslation } from "react-i18next";

// Import css
import './VehicleImageValuation.css'

import { getAngles } from '../../api/vehicleValuation';

import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { CircularProgress } from '@material-ui/core';

// Components
import ImageUpload from './components/ImageUpload/ImageUpload';
import ImageList from './components/ImageList/ImageList';
import ImageModal from './components/ImageModal/ImageModal';
import Progress from './components/Progess/Progress';
import ConfirmImagesWrapper from './components/ConfirmImages/ConfirmImagesWrapper';

const orgAngles = [
    {
        title: 'angles.front.title',
        message: 'angles.front.message',
        id: 'front',
        required: true,
    },
    {
        title: 'angles.hojre.title',
        message: 'angles.hojre.message',
        id: 'hojre',
        required: true,
    },

    {
        title: 'angles.bag.title',
        message: 'angles.bag.message',
        id: 'bag',
        required: true,
    },
    // {
    //     title: 'angles.bagage.title',
    //     message: 'angles.bagage.message',
    //     id: 'bagage',
    //     required: true,
    // },
    {
        title: 'angles.venstre.title',
        message: 'angles.venstre.message',
        id: 'venstre',
        required: true,
    },
    // {
    //     title: 'angles.konsol.title',
    //     message: 'angles.konsol.message',
    //     id: 'konsol',
    //     required: true,
    // },
    {
        title: 'angles.instrument.title',
        message: 'angles.instrument.message',
        id: 'instrument',
        required: true,
    },
    // {
    //     title: 'angles.forsade.title',
    //     message: 'angles.forsade.message',
    //     id: 'forsade',
    //     required: true,
    // },
    // {
    //     title: 'angles.bagsade.title',
    //     message: 'angles.bagsade.message',
    //     id: 'bagsade',
    //     required: true,
    // },
    {
        title: 'angles.skade.title',
        message: 'angles.skade.message',
        id: 'skade',
        required: false,
    },
    {
        title: 'angles.ekstra.title',
        message: 'angles.ekstra.message',
        id: '0-ekstra',
        required: false,
    }
];



class VehicleImageValuation extends Component {

    state = {
        fadeOut: false,
        fadeIn: true,
        loading: false,

        anglesIndex: null, // which angle we're at
        completedAngles: null,
        angles: [],
        sfId: null, // Lead or Opportunity id

        confirmImages: null,
    }

    componentDidMount() {
        // Set correct title
        window.document.title = this.props.t('document-title');

        // 'Id' in header (sf id) is required - checks:
        const { location } = this.props;
        if (location && location.search) {
            const params = queryString.parse(location.search);

            // if theres no offer in redux, get and set it
            if (params.id) {
                this.setState({ sfId: params.id });
                this.getAndSetAngles(params.id)
            }
        }
    }

    // Checks wether all the required images has been uploaded. If so, show 'confirm' button.
    checkForConfirm = (angles) => {
        const missingAngles = orgAngles.reduce((acc, a) => {
            if (a.required && !angles.includes(a.id)) {
                return acc += 1;
            } else return acc;
        }, 0)

        this.setState({ confirmImages: missingAngles === 0 });
    }

    getAndSetAngles = (sfId) => {
        if (!sfId) return;

        // Set loading state
        this.setState({ loading: true });

        // Get angles from azure blob storage
        getAngles(sfId)
            .then(({ success, resp }) => {
                if (success) {
                    let startIndex = 0;
                    let angles = orgAngles.slice(0);
                    let completedAngles = resp.data.angles;

                    if (completedAngles.length > 0) {
                        // If the user has uploaded an extra picture, allow the option to upload another one.
                        const extraAngles = completedAngles.filter((angle => angle.includes('-ekstra')));
                        if (extraAngles) {
                            /* 
                                'extraAngles' is an array of all the uploaded angles like: ["0-ekstra.jpg", "1-ekstra.jpg"]. 
                                Parsing these strings as int, will return the ekstra's number: "0-ekstra" => 0;
                                ParsedExtras will be a list of these 
                            */
                            const parsedExtras = extraAngles.map(angle => parseInt(angle));

                            // Get the number of extras we're at (largest 'extra-number')
                            const largest = Math.max(...parsedExtras);

                            if (largest !== null) {
                                // For each extra, add it to the 'angles' list, plus the additional so they can upload another
                                // Max extras is 5
                                for (let x = 0; x <= largest && x < 4; x++) {
                                    angles.push({
                                        title: 'angles.ekstra.title',
                                        message: 'angles.ekstra.message',
                                        id: `${x + 1}-ekstra`,
                                        required: false,
                                    });
                                }
                            }
                        }


                        // Remove '.jpg' from the returned angles
                        completedAngles = completedAngles.flatMap(angle => {
                            if (angle.includes('.jpg')) {
                                return angle.substring(0, angle.indexOf('.'));
                            } else return [];
                        })

                        const ids = angles.map(angle => angle.id);
                        const indexes = completedAngles.map(angle => ids.indexOf(angle)).sort();


                        // loop thrugh each index in the sorted list: for each index, starting from smallest, check if next picture is taken.
                        // We get/set the first place where an image hasn't been taken yet (if index 1 has been taken, set current image to index 2 if index 2 hasn't been taken)
                        indexes.some(i => { // using 'some' to be able to break loop
                            if (!indexes.includes(i + 1)) {
                                startIndex = (i + 1) === ids.length ? 0 : (i + 1); return true;
                            } else { return false; }
                        })

                    } else {
                        completedAngles = [];
                    }

                    // Check if all required images has been uploaded
                    this.checkForConfirm(completedAngles);

                    this.setState({
                        anglesIndex: startIndex,
                        completedAngles,

                        angles,

                        fadeIn: false,
                        loading: false
                    });

                }
            })
            .catch(err => {
                console.log(err);
                this.props.rollbar('Couldn\'t get angles', { err });
            })
    }


    // When an image is uploaded: show animations, get and set new angles - show these
    uploaded = () => {
        this.setState({ fadeOut: true, loading: true });

        setTimeout(() => {
            this.setState({ fadeOut: false, fadeIn: true });
            this.getAndSetAngles(this.state.sfId);
        }, 400);
    }

    // Find the selected angle's index, and set it as the selected one:
    changeAngle = (id) => {
        const index = this.state.angles.findIndex(angle => angle.id === id);
        this.setState({ anglesIndex: index });
    }


    render() {
        const {
            fadeOut, fadeIn,
            loading,
            angles, anglesIndex, completedAngles,
            confirmImages,
            sfId
        } = this.state;

        const { t } = this.props;

        return (
            <Grid container justify={'center'}>
                <Grid item xs={12} md={6} lg={3}>

                    <div className="pretty-container center">
                        {(sfId !== null && anglesIndex !== null && completedAngles != null && (
                            <div>
                                <Progress angles={angles} completedAngles={completedAngles} currentAngle={angles[anglesIndex]} />

                                {/* If we're loading, hide the card */}
                                <div className={` ${fadeOut ? 'shoot' : ''} ${fadeIn ? 'hide pretty-card' : 'pretty-card'}`}>
                                    <div>
                                        <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {t(angles[anglesIndex].title)}
                                            {(angles[anglesIndex].required === true && (
                                                <ImageModal angle={angles[anglesIndex]} sfId={'exemplary'}>
                                                    <HelpOutlineIcon style={{ color: '#636161' }} />
                                                </ImageModal>
                                            ))}
                                        </h2>
                                        <hr />
                                        <p style={{ wordBreak: 'normal', whiteSpace: 'pre-wrap' }}>
                                            {t(angles[anglesIndex].message)}
                                        </p>
                                    </div>

                                    <div className="center" style={{ diplay: 'flex', flexDirection: 'row' }}>
                                        <ImageUpload
                                            uploaded={this.uploaded} sfId={sfId}
                                            currentAngle={angles[anglesIndex]}
                                            isCurrentAngle={completedAngles.includes(angles[anglesIndex].id)}
                                        />

                                        {/* If an image of this angle has been taken, let user see it by clicking a button. */}
                                        {(completedAngles.includes(angles[anglesIndex].id) && (
                                            <ImageModal angle={angles[anglesIndex]} sfId={sfId}>
                                                <button className="button">
                                                    {t('upload-images.button.inspect-image')}
                                                </button>
                                            </ImageModal>
                                        ))}
                                    </div>
                                </div>



                            </div>

                        )) || (!sfId && (
                            <div className='pretty-card'>
                                <div>
                                    <h2>{t('invalid-url.title')}</h2>
                                    <hr />
                                    <p style={{ wordBreak: 'normal', whiteSpace: 'pre-wrap' }}>
                                        {t('invalid-url.message')}
                                    </p>
                                </div>
                            </div>
                        ))}

                        <ConfirmImagesWrapper confirmImages={confirmImages} sfId={sfId} completedAngles={completedAngles} orgAngles={orgAngles}>
                            <ImageList
                                angles={angles}
                                completedAngles={completedAngles}
                                changeAngle={this.changeAngle}
                                currentAngle={angles[anglesIndex]}
                            />
                        </ConfirmImagesWrapper>

                    </div>

                    {(sfId && loading && fadeIn && (
                        <CircularProgress
                            size={24}
                            style={{ position: 'absolute', color: 'white', top: '6em', left: 'calc(50% - 12px)' }}
                        />
                    ))}
                    <br />
                </Grid>
            </Grid>
        )
    }

}

const mapStateToProps = ({ rollbar }) => {
    return { rollbar }
}


const mapDispatchToProps = () => {
    return {}
}

export default
    compose(
        withTranslation('vehicle_valuation'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(VehicleImageValuation)

