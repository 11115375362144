import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Import css
import styles from './ImageList.module.css';

// I18n
import { withTranslation } from "react-i18next";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';


class ImageList extends Component {

    state = {
        showAll: false
    }


    show() {
        this.setState({ showAll: !this.state.showAll });
    }



    render() {
        const {
            angles, completedAngles, changeAngle, currentAngle,
            t
        } = this.props;

        const { showAll } = this.state;

        return (
            <div className={styles['master-wrapper']}>
                <ul className={styles["check-list"]}>
                    {completedAngles && angles.slice(0, showAll ? angles.length : 6).map(angle => (
                        <li
                            key={angle.id}
                            onClick={() => changeAngle(angle.id)}
                            className={`
                                ${styles.element} 
                                ${currentAngle.id === angle.id ? styles.active : ''}
                                ${styles['fast-transition']}
                            `}
                        >

                            {completedAngles.includes(angle.id) ?
                                <CheckBoxIcon className={styles.icon} style={{ color: '#40ba40' }} /> :
                                angle.required ?
                                    <CheckBoxOutlineBlankIcon className={styles.icon} /> :
                                    <IndeterminateCheckBoxIcon className={styles.icon} />
                            }

                            <div className={styles.text}>
                                {/* 
                                    If the angle is an extra angle, we need to parse i18n the extra number. 
                                    We int-parse the id because it's prepended with the indexed number - parsing returns this number
                                */}
                                <span>{
                                    angle.id.includes('-ekstra') ?
                                        t(angle.title, { ekstra: (parseInt(angle.id) + 1) }) :
                                        t(angle.title)
                                }</span>

                                {/* If the angle has a picture, let the user know */}
                                <span style={{ opacity: 0.3 }}>
                                    {(completedAngles.includes(angle.id) &&
                                        t('angle-list.angle-status.succes')) ||
                                        t('angle-list.angle-status.missing')
                                    }
                                </span>
                            </div>
                        </li>
                    ))}

                    {completedAngles && (
                        <button className="yellow-button" onClick={this.show.bind(this)}>
                            {(showAll &&
                                t('angle-list.buttons.show-less')) ||
                                t('angle-list.buttons.show-more')
                            }
                        </button>
                    )}

                </ul>
            </div>
        )
    }

}

const mapStateToProps = ({ rollbar }) => {
    return { rollbar }
}


const mapDispatchToProps = (dispatch) => {
    return {}
}

export default
    compose(
        withTranslation('vehicle_valuation'),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(ImageList)
